import React, { useEffect, useState } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";
// import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
// import { useLocation, useNavigate } from "react-router-dom";
// import SvgIcon from "../const/SvgIcon";
// import { allapiAction } from "../../Redux/common/action";
import moment from "moment";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
// import { URL } from "../../Redux/common/url";
import HeadingBack from "../../HeadingBack";
// import { useRouter } from "next/router";

// import { URL } from "@/Redux/common/url";
// import { allapiAction } from "@/Redux/common/action";
// import Image from "next/image";
// import { allapiAction } from "@/Redux/common/action";
import SvgIcon from "../../const/SvgIcon";
import { Image } from "react-bootstrap";
import { URL } from "../../../Redux/common/url";
import { useLocation, useNavigate } from "react-router-dom";
import { allapiAction } from "../../../Redux/common/action";
import HeadingBacksalesman from "../../HeadingBack/HeadingBacksalesman";
import { toast } from "react-toastify";

function Expense() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");
  // const pagev = navigate?.query?.page;
  // const searchData = navigate?.query?.search;

  const { confirm } = Modal;

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  const get_product_list = useSelector((state) =>
    state?.allapi?.get_product_list ? state?.allapi?.get_product_list : {}
  );

  const get_product_details = useSelector((state) =>
    state.allapi.get_product_details ? state.allapi.get_product_details : {}
  );

  const [pages, setPage] = useState(1);

  const listdata = get_product_list?.docs ? get_product_list?.docs : [];

  useEffect(() => {
    hendleToget();

    return () => {};
  }, [location]);

  const hendleToget = async () => {
    if (search) {
      dispatch(allapiAction.getexpenselistuser({ page: page, key: search }));
    } else {
      dispatch(allapiAction.getexpenselistuser({ page: page }));
    }
  };
  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
      },
      onCancel() {},
    });
  };

  const hendleDelete = (e) => {
    // deleteProduct
    // setShowDelete(false);
    dispatch(allapiAction.deleteProduct({ productId: e, pageNo: page }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);

  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    dispatch(allapiAction.productDetails(e));
    setuserData(e);
  };

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    // dispatch(
    //   allapiAction.addFoundByAdmin({
    //     id: userData?._id,
    //     amount: statusData?.amount,

    //     pageNumber: page,
    //   })
    // );

    setstatusDataBox(false);
  };

  const showModal = (e) => {
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: userId,
    //     status: userId,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: id,
    //     status: status,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const hendleToStatus = (id, e) => {
    // statusCategory

    // dispatch(allapiAction.statusProduct({ productId: id, status: e }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const hendleToStatusfuture = (id, e) => {
    // statusCategory

    dispatch(
      allapiAction.futuredProduct({ productId: id, status: e, pageNo: page })
    );
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };
  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   key: "image",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <div style={{ width: "100px" }}>
    //           <img
    //             style={{ width: "100%" }}
    //             src={URL?.API_BASE_URL + item?.featureImage}
    //             alt=""
    //           />
    //         </div>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Total ride km",
      dataIndex: "totalridekm",
      key: "totalridekm",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.totalridekm} km
            </span>
          </>
        );
      },
    },
    {
      title: "Bus Train fair",
      dataIndex: "bustrainfair",
      key: "bustrainfair",
    },
    {
      title: "Food",
      dataIndex: "food",
      key: "food",
    },
    {
      title: "laundry",
      dataIndex: "laundry",
      key: "laundry",
    },
    {
      title: "phonebill",
      dataIndex: "phonebill",
      key: "phonebill",
    },
    {
      title: "internetbill",
      dataIndex: "internetbill",
      key: "internetbill",
    },
    {
      title: "localconveyance",
      dataIndex: "localconveyance",
      key: "localconveyance",
    },
    {
      title: "courier",
      dataIndex: "courier",
      key: "courier",
    },
    {
      title: "photocopy",
      dataIndex: "photocopy",
      key: "photocopy",
    },
    {
      title: "othercharge",
      dataIndex: "othercharge",
      key: "othercharge",
    },

    // {
    //   title: "Name",
    //   dataIndex: "title",
    //   key: "title",
    // },
    // {
    //   title: "Price",
    //   dataIndex: "price",
    //   key: "price",
    // },
    // {
    //   title: "After Discount Price",
    //   dataIndex: "afterdiscountprice",
    //   key: "afterdiscountprice",
    // },
    // {
    //   title: "GST",
    //   dataIndex: "gst",
    //   key: "gst",
    // },

    // {
    //   title: "Futured",
    //   dataIndex: "futured",
    //   key: "futured",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <Tooltip title={item?.futured}>
    //           <button
    //             className={
    //               item?.futured == "Yes"
    //                 ? "success-button ml-3"
    //                 : "delete-button2 ml-3"
    //             }
    //             onClick={() =>
    //               hendleToStatusfuture(
    //                 item?._id,
    //                 item?.futured == "Yes" ? "No" : "Yes"
    //               )
    //             }
    //           >
    //             {item?.futured}
    //           </button>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title={item?.status}>
              <button
                className={
                  item?.status == "apporved"
                    ? "success-button ml-3"
                    : item?.status == "pending"
                      ? "admin-button ml-3"
                      : "delete-button2 ml-3"
                }
                // onClick={() =>
                //   hendleToStatus(
                //     item?._id,
                //     item?.status == "Active" ? "Inactive" : "Active"
                //   )
                // }
              >
                {item?.status}
              </button>
            </Tooltip>
          </>
        );
      },
    },

    {
      title: "Date",
      dataIndex: "Join Date",
      key: "Join Date",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {moment(item?.createdAt).format(" DD/MM/YYYY ")}
            </span>
          </>
        );
      },
    },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/account-sales-man/expense?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    navigate("/account-sales-man/expense?page=" + e + "&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  const [statusDataBox2, setstatusDataBox2] = useState(false);
  const [expewnsedata, setexpewnsedata] = useState({});

  const hendleTochange = (e) => {
    const { name, value } = e.target;

    setexpewnsedata({ ...expewnsedata, [name]: value });
  };

  const hendleToSubmit = async () => {
    if (expewnsedata?.startridekm && !expewnsedata?.endridekm) {
      toast?.error("please enter end ride km");
      return;
    }
    if (!expewnsedata?.startridekm && expewnsedata?.endridekm) {
      toast?.error("please enter start ride km");
      return;
    }
    if (
      expewnsedata?.startridekm &&
      expewnsedata?.endridekm &&
      expewnsedata?.startridekm > expewnsedata?.endridekm
    ) {
      toast?.error("please enter start ride km less than end ride km");
      return;
    }
    const totalridekm = expewnsedata?.endridekm - expewnsedata?.startridekm;

    if (totalridekm > 0 && !expewnsedata?.rideby) {
      toast?.error("please select ride by");
      return;
    }
    expewnsedata.totalridekm = totalridekm;
    const rideAmount = totalridekm * (expewnsedata?.rideby == "Car" ? 7 : 3);
    expewnsedata.rideAmount = rideAmount;

    const response = await dispatch(allapiAction.addexpense(expewnsedata));
    hendleToget();
    setstatusDataBox2(false);
  };

  return (
    <div className="container-fluid">
      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <Modal
        title="Add Expense"
        centered
        open={statusDataBox2}
        onOk={() => hendleToSubmit(false)}
        onCancel={() => setstatusDataBox2(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Start Ride km</Col>

            <Col span={24}>
              <input
                type="number"
                name="startridekm"
                className="form-control"
                value={expewnsedata?.startridekm}
                onChange={hendleTochange}
              />
            </Col>
            <Col span={24}>End Ride km</Col>

            <Col span={24}>
              <input
                type="number"
                name="endridekm"
                className="form-control"
                value={expewnsedata?.endridekm}
                onChange={hendleTochange}
              />
            </Col>
            <Col span={24}> Ride By</Col>

            <Col span={24}>
              {/* <input
                type="number"
                name="endridekm"
                className="form-control"
                value={expewnsedata?.endridekm}
                onChange={hendleTochange}
              /> */}

              <select
                className="form-control"
                name="rideby"
                onChange={hendleTochange}
              >
                <option value="">Select</option>
                <option value="Car">Car</option>
                <option value="Bike">Bike</option>
              </select>
            </Col>
            <Col span={24}>Bus Train fair</Col>

            <Col span={24}>
              <input
                type="number"
                name="bustrainfair"
                className="form-control"
                value={expewnsedata?.bustrainfair}
                onChange={hendleTochange}
              />
            </Col>
            <Col span={24}>Food</Col>

            <Col span={24}>
              <input
                type="number"
                name="food"
                className="form-control"
                value={expewnsedata?.food}
                onChange={hendleTochange}
              />
            </Col>
            <Col span={24}>Laundry</Col>

            <Col span={24}>
              <input
                type="number"
                name="laundry"
                className="form-control"
                value={expewnsedata?.laundry}
                onChange={hendleTochange}
              />
            </Col>
            <Col span={24}>Phone bill</Col>

            <Col span={24}>
              <input
                type="number"
                name="phonebill"
                className="form-control"
                value={expewnsedata?.phonebill}
                onChange={hendleTochange}
              />
            </Col>
            <Col span={24}>Internet bill</Col>

            <Col span={24}>
              <input
                type="number"
                name="internetbill"
                className="form-control"
                value={expewnsedata?.internetbill}
                onChange={hendleTochange}
              />
            </Col>
            <Col span={24}>Local conveyance</Col>

            <Col span={24}>
              <input
                type="number"
                name="localconveyance"
                className="form-control"
                value={expewnsedata?.localconveyance}
                onChange={hendleTochange}
              />
            </Col>
            <Col span={24}>Courier</Col>

            <Col span={24}>
              <input
                type="number"
                name="courier"
                className="form-control"
                value={expewnsedata?.courier}
                onChange={hendleTochange}
              />
            </Col>
            <Col span={24}>Photo copy</Col>

            <Col span={24}>
              <input
                type="number"
                name="photocopy"
                className="form-control"
                value={expewnsedata?.photocopy}
                onChange={hendleTochange}
              />
            </Col>
            <Col span={24}>Other charge</Col>

            <Col span={24}>
              <input
                type="number"
                name="othercharge"
                className="form-control"
                value={expewnsedata?.othercharge}
                onChange={hendleTochange}
              />
            </Col>
            <Col span={24}>remark</Col>

            <Col span={24}>
              <input
                type="text"
                name="remark"
                className="form-control"
                value={expewnsedata?.remark}
                onChange={hendleTochange}
              />
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        title="Product Details"
        centered
        open={statusDataBox}
        onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={1000}
      >
        <div>
          <Row>
            <Col span={4}>Name</Col>
            <Col span={8}>{get_product_details?.title}</Col>
            <Col span={4}>Seo Title</Col>
            <Col span={8}>{get_product_details?.seo_title}</Col>
            <Col span={4}>Seo Description</Col>
            <Col span={8}>{get_product_details?.seo_description}</Col>
            <Col span={4}>Price</Col>
            <Col span={8}>{get_product_details?.price}</Col>
            <Col span={4}>Afer Discount Price</Col>
            <Col span={8}>{get_product_details?.afterdiscountprice}</Col>

            <Col span={24}>
              <h3>Variant</h3>
            </Col>
            {get_product_details?.subcolor &&
              get_product_details?.subcolor?.map((data, i) => {
                return (
                  <Col span={24} key={i}>
                    {/* <h6>{data?.color}</h6> */}
                    <Row>
                      <Col span={4}>Color</Col>
                      <Col span={8}>{data?.color}</Col>
                      <Col span={4}>Image</Col>
                      <Col span={8}>
                        <div className="row">
                          {data?.images &&
                            data?.images?.map((Dataimg, indexim) => {
                              return (
                                <div style={{ width: "60px" }} key={indexim}>
                                  <img
                                    src={URL?.API_BASE_URL + Dataimg?.img}
                                    alt=""
                                    style={{ width: "100%" }}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </Col>
                    </Row>

                    {data?.productsize &&
                      data?.productsize?.map((data2, i) => {
                        return (
                          <Row key={i}>
                            <Col span={2}>Size</Col>
                            <Col span={2}>{data2?.size}</Col>
                            <Col span={2}>price</Col>
                            <Col span={2}>{data2?.price}</Col>
                            <Col span={4}>after discount price</Col>
                            <Col span={2}>{data2?.afterdiscountprice}</Col>
                            <Col span={2}>stock</Col>
                            <Col span={2}>{data2?.stock}</Col>
                          </Row>
                        );
                      })}

                    <hr />
                  </Col>
                );
              })}
          </Row>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBacksalesman
            title={"Expense"}
            subHeading={`Total ${get_product_list?.totalDocs || 0} Expense`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          />
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span
              className="jop_btn"
              onClick={() => {
                setstatusDataBox2(true);
                // window.location.href = "/seller/product/add-product";
              }}
            >
              Add
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: get_product_list?.totalDocs
              ? get_product_list?.totalDocs
              : 0,
          }}
        />
      </div>
    </div>
  );
}

export default Expense;
