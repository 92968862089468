import React, { useState, useEffect } from "react";

function CountdownTimer({ targetDate }) {
    const [currentTime, setCurrentTime] = useState(new Date());
    const [targetDate1, setTargetDate] = useState(
        new Date("2024-04-10T10:00:00")
    ); // Target date and time

    useEffect(() => {
        const intervalId = setInterval(() => {
        setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId); // Cleanup function to prevent memory leaks
    }, []);

    const getTimeRemaining = () => {
        const totalTime = targetDate - currentTime;
        const seconds = Math.floor((totalTime / 1000) % 60);
        const minutes = Math.floor((totalTime / (1000 * 60)) % 60);
        const hours = Math.floor((totalTime / (1000 * 60 * 60)) % 24);
        const days = Math.floor(totalTime / (1000 * 60 * 60 * 24));

        return {
        days,
        hours,
        minutes,
        seconds,
        };
    };

    const { days, hours, minutes, seconds } = getTimeRemaining();

    console.log(hours);
    

  return (
    <div>
      {/* <h1>Countdown Timer</h1> */}
      <div>
        {<span>{days} :</span>}
        {<span>{hours.toString().padStart(2, "0")}:</span>}
        <span>{minutes.toString().padStart(2, "0")}:</span>
        <span>{seconds.toString().padStart(2, "0")}</span>
      </div>
    </div>
  );
}

export default CountdownTimer;
