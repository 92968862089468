import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
// import { allapiAction } from "@/Redux/common/action";
import Slider from "react-slick";
import Boxes3 from "./Boxes3";
import moment from "moment";
import { Modal as Modal2 } from "antd";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { allapiAction } from "../../../Redux/common/action";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import { URL } from "../../../Redux/common/url";
import { DeleteToken2 } from "../../utils";
import PaymentForm from "./Payment/PaymentForm";
import UpiPaymentSDK from "./UpiPaymentSDK";
function DeshBoardProduction() {
  const dispatch = useDispatch();
  const [profileModal, setProfileModal] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [userPresentProfile, setUserPresentProfile] = useState({});

  const get_category_sale = useSelector((state) =>
    state?.allapi?.get_category_sale ? state?.allapi?.get_category_sale : []
  );
  console.log(userPresentProfile);

  const get_profile = useSelector((state) =>
    state?.allapi?.get_profile ? state?.allapi?.get_profile : {}
  );

  const profileModalShow = () => setProfileModal(true);
  const profileModalHide = () => setProfileModal(false);

  const router = useNavigate();

  const handleProfileData = (e) => {
    const { name, value } = e.target;
    setUserProfile({
      userProfile,
      [name]: value,
    });
  };

  // useEffect(() => {
  //   const checkPermissions = async () => {
  //     try {
  //       const permission = await navigator.permissions.query({
  //         name: "camera",
  //       });
  //       // console.error(permission);
  //       alert(permission?.state);
  //       // setHasPermission(permission.state === 'granted');
  //     } catch (error) {
  //       console.error("Error checking camera permissions:", error);
  //       alert(error);
  //     }
  //   };

  //   checkPermissions();
  // }, []);

  function submitProfileData() {
    if (!userProfile.fullName) {
      toast.error("Please enter full name!");
    } else if (!userProfile.email) {
      toast.error("Please enter email!");
    } else {
      dispatch(allapiAction.updateUserProfile(userProfile));
      dispatch(allapiAction.getprofile());
      setProfileModal(false);
    }
  }

  useEffect(() => {
    // dispatch(allapiAction.vendordeshBoradData({}));
    // dispatch(allapiAction.categoryWiseSale({}));
    dispatch(allapiAction.getprofile());
    gettodayresent();
    getLocation();
    return () => {};
  }, []);

  const gettodayresent = async () => {
    const response = await dispatch(allapiAction.getTodayPresent());
    if (response?.success) {
      setUserPresentProfile(response?.data);
    }
  };

  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const data2 = [
    ["Year", "Sales", "Expenses"],
    ["Monday", 20000, 30000],
    ["Tuesday", 35000, 15000],
    ["Wednesday", 38000, 14000],
    ["Thursday", 25000, 35000],
    ["Friday", 34000, 22000],
    ["Saturday", 42000, 34000],
    ["Sunday", 18000, 38000],
  ];
  const data = get_category_sale;

  const options = {
    title: "Company Performance",
    curveType: "function",
    legend: { position: "bottom" },
  };

  let pageUrl =
    "https://devimart.com/referalsignup?ref=" + get_profile?.referId;

  function copy() {
    const el = document.createElement("input");
    el.value = pageUrl;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // setCopied(true);
    toast.success("Your Referal link Copied!");

    // setstshareshow(false);
  }

  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [error, setError] = useState(null);

  const hendetoloacation = () => {
    function getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            // Success: Fill the fields with latitude and longitude
            document.getElementById("lat").value = position.coords.latitude;
            document.getElementById("long").value = position.coords.longitude;
          },
          function (error) {
            // Error handling
            switch (error.code) {
              case error.PERMISSION_DENIED:
                alert("User denied the request for Geolocation.");
                break;
              case error.POSITION_UNAVAILABLE:
                alert("Location information is unavailable.");
                break;
              case error.TIMEOUT:
                alert("The request to get user location timed out.");
                break;
              case error.UNKNOWN_ERROR:
                alert("An unknown error occurred.");
                break;
            }
          }
        );
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    }

    // Trigger location request on page load
    // window.onload = getLocation;
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);

          // Send the location to your server
          // axios
          //   .post("http://localhost:3000/location", {
          //     latitude: position.coords.latitude,
          //     longitude: position.coords.longitude,
          //   })
          //   .then((response) => {
          //     console.log(response.data);
          //   })
          //   .catch((error) => {
          //     console.error("There was an error sending the location!", error);
          //   });
        },
        (error) => {
          setError(error.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  const [user, setuser] = useState({});

  const webcamRef = useRef(null);
  const [image, setImage] = useState(null);
  console.log(image);

  const base64ToBlob = (base64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(base64Data.split(",")[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const hendletotest = (e) => {
    const blob = base64ToBlob(image, "image/jpeg");
    console.log(blob);
  };

  // कैप्चर करने के लिए फंक्शन
  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
  };

  const [statusDataBox, setstatusDataBox] = useState(false);

  const [mangeloginLogot, setmangeloginLogot] = useState("");
  const hendleToin = async () => {
    // PresentIn

    if (mangeloginLogot == "in") {
      const blob = base64ToBlob(image, "image/jpeg");

      const formData = new FormData();
      formData.append("image", blob, "photo.jpg");
      formData.append("latitude", latitude);
      formData.append("longitude", longitude);

      const response = await dispatch(allapiAction.PresentIn(formData));
      gettodayresent();
    } else {
      const blob = base64ToBlob(image, "image/jpeg");

      const formData = new FormData();
      formData.append("image", blob, "photo.jpg");
      formData.append("latitude", latitude);
      formData.append("longitude", longitude);
      const response = await dispatch(allapiAction.PresentOut(formData));
      gettodayresent();
    }
    setIsCameraOn(false);
    setTimeout(() => {
      setstatusDataBox(false);
      setImage("");
    }, 200);
  };

  const hendleToOut = async () => {
    // PresentIn
    const response = await dispatch(
      allapiAction.PresentOut({ latitude, longitude })
    );
    gettodayresent();
  };
  const [facingMode, setFacingMode] = useState("user"); // शुरू में फ्रंट कैमरा

  // कैमरा फ्लिप करने के लिए फंक्शन
  const flipCamera = () => {
    setFacingMode((prevState) =>
      prevState === "user" ? "environment" : "user"
    );
  };
  const videoConstraints = {
    facingMode: facingMode, // कैमरा फ्रंट या बैक चुनने के लिए
  };

  const [isCameraOn, setIsCameraOn] = useState(false);
  const hendleToViewM = (e) => {
    if (e == "out" && userPresentProfile?.login == "false") {
      toast.error("Please Login First");
      return;
    }

    setstatusDataBox(true);
    setIsCameraOn(true);
    setmangeloginLogot(e);
  };

  const toggleCamera = () => {
    setIsCameraOn((prevState) => !prevState);
  };
  const toggleCamera2 = () => {
    setIsCameraOn(false);
    setTimeout(() => {
      setstatusDataBox(false);
    }, 200);
  };

  const handleLogout = () => {
    DeleteToken2();
    router("/login");
  };

  const [paymentStatus, setPaymentStatus] = useState("");

  const handlePayment = async () => {
    const paymentConfig = {
      vpa: "sbipmopad.02sob0000009754-ym556930@sbipay", // Replace with the receiver's UPI ID
      amount: "1.00", // Amount for testing
      payeeName: "ReceiverName",
      transactionRef: "TXN12345VS", // Unique transaction reference
      callbackUrl: "http://localhost:3000/payment-status",
      transactionNote: "Payment fortesting",
    };
    // upi://pay?pa=9610641351%40ybl&pn=ReceiverName&tid=TXN12345VS&am=1.00&tn=Payment%20fortesting&cu=INR&url=
    try {
      const response = await UpiPaymentSDK.initializePayment(paymentConfig);
      console.log("Payment Response:", response);
      alert("Payment Process Initiated!");
    } catch (error) {
      console.error("Payment Error:", error);
      alert(`Payment Failed: ${error.message}`);
    }
  };

  // const requestCameraAccess = async () => {
  //   try {
  //     const stream = await navigator.mediaDevices.getUserMedia({
  //       video: true,
  //       audio: false, // Set to true if you need audio
  //     });
  //     // Attach the stream to a video element or process it as needed
  //   } catch (error) {
  //     if (error.name === "NotAllowedError") {
  //       alert(
  //         "Camera access was denied. Please enable it in your browser settings.",
  //         error
  //       );
  //     } else {
  //       console.error("Error accessing the camera:", error);
  //       alert(
  //         "Camera access was denied. Please enable it in your browser settings.",
  //         error
  //       );
  //     }
  //     // Handle errors (e.g., show a message to the user)
  //   }
  // };

  // useEffect(() => {
  //   requestCameraAccess();
  //   return () => {};
  // }, []);

  return (
    <div>
      <Modal2
        title="Click"
        centered
        open={statusDataBox}
        onOk={() => hendleToin(false)}
        onCancel={() => toggleCamera2(false)}
        width={500}
      >
        {/* <h1>Camera On/Off Example</h1>
      <button onClick={toggleCamera}>
        {isCameraOn ? "Turn Off Camera" : "Turn On Camera"}
      </button> */}
        {isCameraOn && (
          <>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={320}
              height={240}
              videoConstraints={videoConstraints}
            />
            <div>
              <button onClick={flipCamera} className=" btn btn-info">
                {facingMode === "user"
                  ? "Switch to Back Camera"
                  : "Switch to Front Camera"}
              </button>
              <button onClick={capture} className="ml-3 btn btn-primary">
                Capture photo
              </button>
              {/* <button onClick={hendletotest}>Capture photo</button> */}
              {image && (
                <>
                  <img src={image} alt="Captured" className="mt-5" />
                  {/* <button onClick={uploadImage}>Upload Photo</button> */}
                </>
              )}
            </div>{" "}
          </>
        )}
      </Modal2>
      {/* <button onClick={requestCameraAccess}>Open Camera</button> */}
      <Row justify={"center"}>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className=" p-10">
            <Row gutter={[0, 20]}>
              {/* <div class="buttons">
    <button class="btn-hover color-1">BUTTON</button>
    <button class="btn-hover color-2">BUTTON</button>
    <button class="btn-hover color-3">BUTTON</button>
    <button class="btn-hover color-4">BUTTON</button>
    <button class="btn-hover color-5">BUTTON</button>
    <button class="btn-hover color-6">BUTTON</button>
    <button class="btn-hover color-7">BUTTON</button>
    <button class="btn-hover color-8">BUTTON</button>
    <button class="btn-hover color-9">BUTTON</button>
    <button class="btn-hover color-10">BUTTON</button>
    <button class="btn-hover color-11">BUTTON</button>
</div> */}
              <Col lg={24} md={24} sm={24} xs={24}>
                <div className="flex1 p-10 weather_section  b2s m-5 cursor-pointer">
                  <div className="row justify-content-between align-item-center">
                    <div className="col-12 d-flex justify-content-end">
                      <Button
                        type="primary"
                        danger
                        onClick={handleLogout}
                        style={{ marginRight: "10px" }}
                      >
                        Logout
                      </Button>
                    </div>
                    {/* <div className="col-12 ">
                      <div
                        style={{
                          // textAlign: "center",
                          width: "100px",
                          // height: "100px",
                        }}
                      >
                        <img
                          src="/img/message/sudarshanchakra.gif"
                          style={{ width: "100%" }}
                          alt="profile"
                        />
                      </div>
                    </div> */}
                    <div className="col-12 col-md-3 ">
                      <div
                        style={{
                          // textAlign: "center",
                          width: "100px",
                          // height: "100px",
                        }}
                      >
                        <img
                          src="/img/message/sudarshanchakra.gif"
                          style={{ width: "100%" }}
                          alt="profile"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-9">
                      <div className="relative boxHeadingTotal">
                        <div className="fw-600">
                          Uid : {get_profile?.referId}
                        </div>
                      </div>
                      <div className="relative boxHeadingTotal">
                        <div className="fw-600">
                          Name : {get_profile?.fullName}
                        </div>
                      </div>
                      <div className="relative boxHeadingTotal">
                        <div className="fw-600">
                          Email : {get_profile?.email}
                        </div>
                      </div>
                      <div className="relative boxHeadingTotal">
                        <div className="fw-600">
                          Phone : {get_profile?.phone}
                        </div>
                      </div>
                      {/* <div className="relative boxHeadingTotal">
                        <div className="fw-600">
                          Referral Program : {get_profile?.isrefferal}
                        </div>
                      </div> */}

                      <div className="relative boxHeadingTotal">
                        <div class="row">
                          <div class="col-6 col-md-6">
                            <div class="buttons">
                              {userPresentProfile?.login == "false" ? (
                                <>
                                  {/* <aside>
                                    <i
                                      id="arrow"
                                      class="fas fa-long-arrow-alt-down  fa-2x"
                                    ></i>
                                  </aside> */}
                                  <button
                                    id="button"
                                    // onClick={() => navigate("/donation/" + id)}
                                    onClick={() => {
                                      hendleToViewM("in");
                                    }}
                                  >
                                    {" "}
                                    In
                                  </button>
                                </>
                              ) : (
                                <button
                                  class="btn-hover color-2"
                                  // onClick={() => {
                                  //   hendleToViewM("in");
                                  // }}
                                >
                                  In
                                </button>
                              )}
                            </div>
                          </div>

                          <div class="col-6 col-md-6">
                            <div class="buttons">
                              {userPresentProfile?.logout == "false" ? (
                                <button
                                  class="btn-hover color-2"
                                  onClick={() => {
                                    hendleToViewM("out");
                                  }}
                                >
                                  Out
                                </button>
                              ) : (
                                <button
                                  class="btn-hover color-2"
                                  // onClick={() => {
                                  //   copy();
                                  // }}
                                >
                                  Out
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <Button
                        type="primary"
                        title="Update"
                        onClick={profileModalShow}
                      >
                        Update
                      </Button> */}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        {/* <Col lg={24} md={24} sm={24} xs={24}>
          <div className=" p-10">
            <div className="hometest">
              <Slider {...settings}>
                <div>
                  <a className="ps-banner__overlay" href="#">
                    <img src="/img/slider1.jpg" alt="" />
                  </a>
                </div>
                <div className="ps-banner bg--cover">
                  <a className="ps-banner__overlay" href="#">
                    <img src="/img/slider2.jpg" alt="" />
                  </a>
                </div>
                <div className="ps-banner bg--cover">
                  <a className="ps-banner__overlay" href="#">
                    <img src="/img/slider3.jpg" alt="" />
                  </a>
                </div>
                <div className="ps-banner bg--cover">
                  <a className="ps-banner__overlay" href="#">
                    <img src="/img/slider4.jpg" alt="" />
                  </a>
                </div>
              </Slider>
            </div>
          </div>
        </Col> */}
        {/* <Col lg={24} md={24} sm={24} xs={24}>
          <div className=" p-10">
            <Boxes3 />
          </div>
        </Col> */}
      </Row>
      <Row justify={"center"}>
        {/* <Col lg={24} md={24} sm={24} xs={24}>
          <div style={{ width: "100px", margin: "0 auto" }}>
            <img
              src="/img/message/sudarshanchakra.gif"
              style={{ width: "100%" }}
              alt="profile"
            />
          </div>
        </Col> */}

        {/* <Col lg={24} md={24} sm={24} xs={24}>
          <div className="p-10">
            <div
              className="p-10 shadow-lg rounded-lg"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="flex1">
                <img
                  src={"/img/message/sudarshanchakra.gif"}
                  style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                  alt="profile"
                />
              </div>
              <div className="flex1 text-center">
                <h3 className="m-0">{get_profile?.fullName}</h3>
                <p className="m-0">{get_profile?.email}</p>
                <p className="m-0">+91 {get_profile?.phone}</p>
                <p className="m-0">Employee Id: {get_profile?.referId}</p>
              </div>
              <div className="flex1 text-right">
                <Button type="primary" onClick={() => handleLogout()}>
                  Logout
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div style={{ width: "100px", margin: "0 auto" }}>
            <button
              type="primary"
              className="   text-white p-5 pl-20 pr-20 rounded-lg border-0"
              style={{ backgroundColor: "green" }}
              block
              size="large"
              // onClick={() => {
              //   hendleToViewM("in");
              // }}
            >
              Login
            </button>
          </div>
        </Col> */}
        {/* <div>
          <h1>UPI Payment Example</h1>
          <button onClick={handlePayment}>Pay with UPI</button>
          <p>Status: {paymentStatus}</p>
        </div>
        <div>
          <h1>UPI Payment Example</h1>
          <button onClick={handlePayment}>Pay with UPI</button>
        </div> */}
        {/* <Col lg={24} md={24} sm={24} xs={24}>
          <div className="p-10">
            <div
              className="p-10  "
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="flex1">
                {userPresentProfile?.login == "false" ? (
                  <button
                    type="primary"
                    className="   text-white p-5 pl-20 pr-20 rounded-lg border-0"
                    style={{ backgroundColor: "green" }}
                    block
                    size="large"
                    onClick={() => {
                      hendleToViewM("in");
                    }}
                  >
                    In
                  </button>
                ) : (
                  <button
                    type="primary"
                    className="   text-white p-5 pl-20 pr-20 rounded-lg border-0"
                    style={{ backgroundColor: "#787b60" }}
                    block
                    size="large"
                    // onClick={() => {
                    //   hendleToin();
                    // }}
                  >
                    In
                  </button>
                )}
              </div>
              <div className="flex1 text-center">
                {userPresentProfile?.logout == "false" ? (
                  <button
                    type="primary"
                    className="   text-white p-5 pl-20 pr-20 rounded-lg border-0"
                    style={{ backgroundColor: "green" }}
                    block
                    size="large"
                    onClick={() => {
                      hendleToViewM("out");
                    }}
                  >
                    Out
                  </button>
                ) : (
                  <button
                    type="primary"
                    className="   text-white p-5 pl-20 pr-20 rounded-lg border-0"
                    style={{ backgroundColor: "#787b60" }}
                    block
                    size="large"
                    // onClick={() => {
                    //   hendleToin();
                    // }}
                  >
                    Out
                  </button>
                )}
              </div>
              <div className="flex1 text-right">
                <button
                  type="primary"
                  className="   text-white p-5 pl-20 pr-20 rounded-lg border-0"
                  style={{ backgroundColor: "brown" }}
                  size="large"
                  //   onClick={() => {
                  //     hendleToViewM();
                  //   }}
                >
                  Stop
                </button>
              </div>
            </div>
          </div>
        </Col> */}
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className=" p-10">
            <div className="p-10 shadow-lg rounded-lg">
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  In
                </Col>
                <Col lg={12} md={12} sm={12} xs={12}>
                  Out
                </Col>
                <Col lg={12} md={12} sm={12} xs={12}>
                  {/* {userPresentProfile?.loginTime} */}
                  {userPresentProfile?.loginImage && (
                    <div style={{ border: "2px solid green", padding: "10px" }}>
                      {userPresentProfile?.loginImage && (
                        <div style={{ width: "50px" }}>
                          <img
                            src={
                              URL?.API_BASE_URL + userPresentProfile?.loginImage
                            }
                            style={{
                              width: "100%",
                              borderRadius: "50%",
                              height: "50px",
                            }}
                          />
                        </div>
                      )}
                      {userPresentProfile?.loginTime &&
                        moment(userPresentProfile?.loginTime).format(
                          " DD/MM/YYYY  hh:mm A"
                        )}
                    </div>
                  )}
                </Col>
                <Col lg={12} md={12} sm={12} xs={12}>
                  {userPresentProfile?.logoutImage && (
                    <div style={{ border: "2px solid blue", padding: "10px" }}>
                      {userPresentProfile?.logoutImage && (
                        <div style={{ width: "50px" }}>
                          <img
                            src={
                              URL?.API_BASE_URL +
                              userPresentProfile?.logoutImage
                            }
                            style={{
                              width: "100%",
                              borderRadius: "50%",
                              height: "50px",
                            }}
                          />
                        </div>
                      )}
                      {userPresentProfile?.logoutTime &&
                        moment(userPresentProfile?.logoutTime).format(
                          " DD/MM/YYYY  hh:mm A"
                        )}
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        {/* <Col lg={6} md={12} sm={12} xs={12}>
          <div className=" p-10">
            <div className="p-10 shadow-lg rounded-lg">
              <div className="flex justify-content-center align-item-center">
                <div className="flex align-item-center">
                  <div className="text-center ">
                    <div
                      className="text-lg font-semibold"
                      style={{
                        // width: "70px",
                        height: "70px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        border: "2px solid green",
                      }}
                    >
                      <i
                        className="fas fa-money-bill-wave text-2xl text-green-500"
                        style={{ color: "green", fontSize: "20px" }}
                      ></i>
                    </div>
                    <div className="text-lg font-semibold">Total Income</div>
                    <div className="text-2xl font-bold">
                      {userPresentProfile?.totalIncome
                        ? userPresentProfile?.totalIncome
                        : 0}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={6} md={12} sm={12} xs={12}>
          <div className=" p-10">
            <div className="p-10 shadow-lg rounded-lg">
              <div className="flex justify-content-center align-item-center">
                <div className="flex align-item-center">
                  <div className="text-center ">
                    <div
                      className="text-lg font-semibold"
                      style={{
                        // width: "70px",
                        height: "70px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        border: "2px solid green",
                      }}
                    >
                      <i
                        className="fas fa-money-bill-wave text-2xl text-green-500"
                        style={{ color: "green", fontSize: "20px" }}
                      ></i>
                    </div>
                    <div className="text-lg font-semibold">Total Income</div>
                    <div className="text-2xl font-bold">
                      {userPresentProfile?.totalIncome
                        ? userPresentProfile?.totalIncome
                        : 0}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={6} md={12} sm={12} xs={12}>
          <div className=" p-10">
            <div className="p-10 shadow-lg rounded-lg">
              <div className="flex justify-content-center align-item-center">
                <div className="flex align-item-center">
                  <div className="text-center ">
                    <div
                      className="text-lg font-semibold"
                      style={{
                        // width: "70px",
                        height: "70px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        border: "2px solid green",
                      }}
                    >
                      <i
                        className="fas fa-money-bill-wave text-2xl text-green-500"
                        style={{ color: "green", fontSize: "20px" }}
                      ></i>
                    </div>
                    <div className="text-lg font-semibold">Total Income</div>
                    <div className="text-2xl font-bold">
                      {userPresentProfile?.totalIncome
                        ? userPresentProfile?.totalIncome
                        : 0}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={6} md={12} sm={12} xs={12}>
          <div className=" p-10">
            <div className="p-10 shadow-lg rounded-lg">
              <div className="flex justify-content-center align-item-center">
                <div className="flex align-item-center">
                  <div className="text-center ">
                    <div
                      className="text-lg font-semibold"
                      style={{
                        // width: "70px",
                        height: "70px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        border: "2px solid green",
                      }}
                    >
                      <i
                        className="fas fa-money-bill-wave text-2xl text-green-500"
                        style={{ color: "green", fontSize: "20px" }}
                      ></i>
                    </div>
                    <div className="text-lg font-semibold">Total Income</div>
                    <div className="text-2xl font-bold">
                      {userPresentProfile?.totalIncome
                        ? userPresentProfile?.totalIncome
                        : 0}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col> */}
        {/* <Col lg={6} md={12} sm={12} xs={12}>
          <div
            className=" p-10"
            onClick={() => {
              router("/account-sales-man/visit-site");
            }}
          >
            <div className="p-10 shadow-lg rounded-lg">
              <div className="flex justify-content-center align-item-center">
                <div className="flex align-item-center">
                  <div className="text-center " style={{ minWidth: "70px" }}>
                    <div
                      className="text-lg font-semibold"
                      style={{
                        // width: "70px",
                        height: "70px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        border: "2px solid green",
                        backgroundColor: "green",
                      }}
                    >
                      <i
                        className="fas fa-location-arrow text-2xl text-green-500"
                        style={{ color: "white", fontSize: "20px" }}
                      ></i>
                    </div>
                    <div className="text-lg font-semibold"> Visit site</div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col> */}
        {/* <Col lg={6} md={12} sm={12} xs={12}>
          <div
            className=" p-10"
            onClick={() => {
              router("/account-sales-man/visit-site");
            }}
          >
            <div className="p-10 shadow-lg rounded-lg">
              <div className="flex justify-content-center align-item-center">
                <div className="flex align-item-center">
                  <div className="text-center ">
                    <div
                      className="text-lg font-semibold"
                      style={{
                        // width: "70px",
                        height: "70px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        border: "2px solid blue",
                        backgroundColor: "blue",
                      }}
                    >
                      <i
                        className="fas fa-user text-2xl text-green-500"
                        style={{ color: "white", fontSize: "20px" }}
                      ></i>
                    </div>
                    <div className="text-lg font-semibold"> Attendence</div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col> */}
        <Col lg={6} md={12} sm={12} xs={12}>
          <div
            className=" p-10"
            onClick={() => {
              router("/producation/order");
            }}
          >
            <div className="p-10 shadow-lg rounded-lg">
              <div className="flex justify-content-center align-item-center">
                <div className="flex align-item-center">
                  <div className="text-center ">
                    <div
                      className="text-lg font-semibold"
                      style={{
                        // width: "70px",
                        height: "70px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        border: "2px solid #27a72c",
                        // backgroundColor: "white",
                        backgroundImage:
                          "linear-gradient(to right, #f5ce62, #5ee403, #27a72c, #27a72c)",
                      }}
                    >
                      <i
                        className="fas fa-store text-2xl text-green-500"
                        style={{ color: "#ffffff", fontSize: "20px" }}
                      ></i>
                    </div>
                    <div className="text-lg font-semibold"> Order List</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        {/* <Col lg={6} md={12} sm={12} xs={12}>
          <div
            className=" p-10"
            onClick={() => {
              router("/account-sales-man/expense");
            }}
          >
            <div className="p-10 shadow-lg rounded-lg">
              <div className="flex justify-content-center align-item-center">
                <div className="flex align-item-center">
                  <div className="text-center ">
                    <div
                      className="text-lg font-semibold"
                      style={{
                        // width: "70px",
                        height: "70px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        border: "2px solid #e43603",
                        // backgroundColor: 
                        backgroundImage: "linear-gradient(to right, #f5ce62, #e43603, #c9446a, #e85a19)",
                      }}
                    >
                      <i
                        className="fas fa-file-invoice-dollar text-2xl text-green-500"
                        style={{ color: "white", fontSize: "20px" }}
                      ></i>
                    </div>
                    <div className="text-lg font-semibold"> Expense List</div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col> */}
      </Row>
      {/* <Col lg={24} md={24} sm={24} xs={24}>
        <div className="p-10">
          <div
            className="p-10  "
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="flex1">
              <button
                type="primary"
                className="   text-white p-5 pl-20 pr-20 rounded-lg border-0"
                style={{ backgroundColor: "#787b60" }}
                block
                size="large"
                // onClick={() => {
                //   hendleToin();
                // }}
                onClick={() => {
                  router("/account-sales-man/visit-site");
                }}
              >
                Visit site
              </button>
            </div>
             <PaymentForm/> 
            <div className="flex1">
              <button
                type="primary"
                className="   text-white p-5 pl-20 pr-20 rounded-lg border-0"
                style={{ backgroundColor: "#787b60" }}
                block
                size="large"
                // onClick={() => {
                //   hendleToin();
                // }}
                onClick={() => {
                  router("/account-sales-man/attendence");
                }}
              >
                Attendence
              </button>
            </div>
            <div className="flex1 text-center">
              <button
                type="primary"
                className="   text-white p-5 pl-20 pr-20 rounded-lg border-0"
                style={{ backgroundColor: "#787b60" }}
                block
                size="large"
                // onClick={() => {
                //   hendleToin();
                // }}
              >
                Website
              </button>
            </div>
            <div className="flex1 text-right">
              <button
                type="primary"
                className="   text-white p-5 pl-20 pr-20 rounded-lg border-0"
                style={{ backgroundColor: "brown" }}
                size="large"
                onClick={() => {
                  router("/account-sales-man/order-proccess ");
                }}
              >
                Order Now
              </button>
            </div>
          </div>
          <div
            className="p-10  "
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="flex1">
              <button
                type="primary"
                className="   text-white p-5 pl-20 pr-20 rounded-lg border-0"
                style={{ backgroundColor: "#787b60" }}
                block
                size="large"
                // onClick={() => {
                //   hendleToin();
                // }}
              >
                Add Expense
              </button>
            </div>
            <div className="flex1 text-center">
              <button
                type="primary"
                className="   text-white p-5 pl-20 pr-20 rounded-lg border-0"
                style={{ backgroundColor: "#787b60" }}
                block
                size="large"
                // onClick={() => {
                //   hendleToin();
                // }}
                onClick={() => {
                  router("/account-sales-man/expense");
                }}
              >
                Expense List
              </button>
            </div>
            <div className="flex1 text-right">
              <button
                type="primary"
                className="   text-white p-5 pl-20 pr-20 rounded-lg border-0"
                style={{ backgroundColor: "brown" }}
                size="large"
                // onClick={() => {
                //   hendleToViewM();
                // }}
              >
                Chat Support
              </button>
            </div>
          </div>
        </div>
      </Col> */}
      {/* marquse */}
      <Modal show={profileModal} onHide={profileModalHide} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card mx-auto">
            <div className="card-body">
              <div className="mb-3">
                <label className="form-label">Full Name</label>
                <input
                  name="fullName"
                  className="form-control"
                  placeholder="Enter full Name"
                  type="text"
                  value={get_profile.fullName}
                  onChange={handleProfileData}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Email</label>
                <input
                  name="email"
                  className="form-control"
                  placeholder="Enter Email"
                  type="text"
                  value={get_profile.email}
                  onChange={handleProfileData}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={profileModalHide}>
            Cancel
          </Button>
          <Button variant="primary" onClick={submitProfileData}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DeshBoardProduction;
