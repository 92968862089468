import React from "react";
import {
  CiCircleFilled,
  CiCircleTwoTone,
  CustomerServiceFilled,
  DashboardOutlined,
  ExportOutlined,
  FolderAddFilled,
  MoneyCollectFilled,
  OrderedListOutlined,
  QuestionCircleOutlined,
  TeamOutlined,
  WeiboSquareFilled,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import Logo from "./Logo";
// import { ShowMenu } from "../../utils";
// import { useRouter } from "next/router";
import { useBasePath } from "../../hooks/useBasePath";
import { ShowMenu } from "../../utils";
import { useNavigate } from "react-router-dom";
const { Sider } = Layout;

const Sidebar = ({ collapsed }) => {
  const navigate = useNavigate();
  const { openKey, selectKey } = useBasePath();

  return (
    <Sider
      className="layout_sidebar1"
      collapsible
      collapsed={collapsed}
      width={collapsed ? 0 : 200}
      trigger={null}
      style={{ display: collapsed ? "none" : "block" }}
    >
      <Logo collapsed={collapsed} />
      <div className="sidebar_menus">
        <Menu
          mode="inline"
          theme="dark"
          selectedKeys={selectKey}
          defaultOpenKeys={openKey}
          style={{ background: "#191352" }}
          items={[
            {
              key: "dashboard",
              icon: <DashboardOutlined />,
              label: "Dashboard Management",
              onClick: () => {
                navigate("/accounts");
              },
            },
            {
              key: "order",
              icon: <OrderedListOutlined />,
              label: "Order",
              style: { display: ShowMenu(["Order"]) },
              onClick: () => {
                navigate("/accounts/order");
              },
            },
            // {
            //   key: "income-all",
            //   icon: <ExportOutlined />,
            //   label: "income",
            //   style: {
            //     display: ShowMenu([
            //       "Direct Income",
            //       "Level Income",
            //       "Bonus Income",
            //       "Matching Income",
            //       "Redirect Income",
            //       "Relevel Income",
            //       "Rematching Income",
            //     ]),
            //   },
            //   children: [
            //     {
            //       key: "direct-income",
            //       label: "Direct Income",
            //       icon: <ExportOutlined />,
            //       style: { display: ShowMenu(["Direct Income"]) },
            //       onClick: () => {
            //         navigate("/accounts/direct-income");
            //       },
            //     },

            //     {
            //       key: "level-income",
            //       label: "Level Income",
            //       icon: <WeiboSquareFilled />,
            //       style: { display: ShowMenu(["Level Income"]) },
            //       onClick: () => {
            //         navigate("/accounts/level-income");
            //       },
            //     },
            //     {
            //       key: "bonus-income",
            //       label: "bonus-income",
            //       icon: <i className="fa fa-check"></i>,
            //       style: { display: ShowMenu(["Bonus Income"]) },
            //       onClick: () => {
            //         navigate("/accounts/bonus-income");
            //       },
            //     },
            //     {
            //       key: "matching-income",
            //       label: "Matching Income",
            //       icon: <i className="fa fa-repeat"></i>,
            //       style: { display: ShowMenu(["Matching Income"]) },
            //       onClick: () => {
            //         navigate("/accounts/matching-income");
            //       },
            //     },
            //     {
            //       key: "redirect-income",
            //       label: "Redirect Income",
            //       icon: <i className="fa fa-outdent"></i>,
            //       style: { display: ShowMenu(["Redirect Income"]) },
            //       onClick: () => {
            //         navigate("/accounts/redirect-income");
            //       },
            //     },
            //     {
            //       key: "relevel-income",
            //       label: "Relevel Income",
            //       icon: <i className="fa fa-truck"></i>,
            //       style: { display: ShowMenu(["Relevel Income"]) },
            //       onClick: () => {
            //         navigate("/accounts/relevel-income");
            //       },
            //     },
            //     {
            //       key: "rematching-income",
            //       label: "Rematching Income",
            //       icon: <i className="fa fa-repeat"></i>,
            //       style: { display: ShowMenu(["Rematching Income"]) },
            //       onClick: () => {
            //         navigate("/accounts/rematching-income");
            //       },
            //     },
            //     // {
            //     //   key: "canceled",
            //     //   label: "Canceled",
            //     //   icon: <i className="fa fa-user-times"></i>,
            //     //   style: { display: ShowMenu(["Canceled"]) },
            //     //   onClick: () => {
            //     //     navigate("/accounts/canceled");
            //     //   },
            //     // },
            //   ],
            // },
            // {
            //   key: "lost-and-found",
            //   icon: <FolderAddFilled />,
            //   label: "Lost And Found",
            //   style: { display: ShowMenu(["Lost And Found"]) },
            //   onClick: () => {
            //     navigate("/accounts/lost-and-found");
            //   },
            // },
            // {
            //   key: "certificates",
            //   icon: <CiCircleFilled />,
            //   label: "Certificates",
            //   style: { display: ShowMenu(["Certificates"]) },
            //   onClick: () => {
            //     navigate("/accounts/certificates");
            //   },
            // },
            // {
            //   key: "apply-jobs",
            //   icon: <CiCircleFilled />,
            //   label: "Applied Jobs",
            //   style: { display: ShowMenu(["Applied Jobs"]) },
            //   onClick: () => {
            //     navigate("/accounts/apply-jobs");
            //   },
            // },
            // {
            //   key: "direct-team",
            //   icon: <TeamOutlined />,
            //   label: "Direct Team",
            //   style: { display: ShowMenu(["Direct Team"]) },
            //   onClick: () => {
            //     navigate("/accounts/direct-team");
            //   },
            // },
            // {
            //   key: "genelogy",
            //   icon: <TeamOutlined />,
            //   label: "Genelogy",
            //   style: { display: ShowMenu(["Genelogy"]) },
            //   onClick: () => {
            //     navigate("/accounts/genelogy");
            //   },
            // },
            // {
            //   key: "total-team",
            //   icon: <TeamOutlined />,
            //   label: "Total Team",
            //   style: { display: ShowMenu(["Total Team"]) },
            //   onClick: () => {
            //     navigate("/accounts/total-team");
            //   },
            // },

            
            // {
            //   key: "level-income",
            //   icon: <TeamOutlined />,
            //   label: "Level Income",
            //   style: { display: ShowMenu(["Level Income"]) },
            //   onClick: () => {
            //     navigate("/accounts/level-income");
            //   },
            // },
            // {
            //   key: "my-income",
            //   icon: <MoneyCollectFilled />,
            //   label: "My Income",
            //   style: { display: ShowMenu(["My Income"]) },
            //   onClick: () => {
            //     navigate("/accounts/my-income");
            //   },
            // },
            {
              key: "support-ticket",
              icon: <CustomerServiceFilled />,
              label: "Support Ticket",
              style: { display: ShowMenu(["Support Ticket"]) },
              onClick: () => {
                navigate("/accounts/support-ticket");
              },
            },
          ]}
        />
      </div>
    </Sider>
  );
};

export default Sidebar;
