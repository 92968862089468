import React, { useEffect, useLayoutEffect, useState } from "react";
import ReactImageMagnify from "react-image-magnify";
// import Image from "next/image";
// import { URL } from "@/Redux/common/url";
import { useDispatch, useSelector } from "react-redux";
// import { useRouter } from "next/router";
// import { allapiAction } from "@/Redux/common/action";
import Slider from "react-slick";
// import Link from "next/link";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { URL } from "../../Redux/common/url";
import { allapiAction } from "../../Redux/common/action";
import { Image } from "react-bootstrap";
import CoustomImg from "../CoustomImg/CoustomImg";

function ProductDetailsPub({}) {
  const dispatch = useDispatch();
  const router = useNavigate();
  const { id } = useParams();
  const [post, setpost] = useState({});
  const [reletedProduct, setreletedProduct] = useState([]);
  const [reletedProductbrand, setreletedProductbrand] = useState([]);
  const [shopDetails, setshopDetails] = useState({});
  console.log(shopDetails);
  const [productImage, setproductImage] = useState([]);
  const [productColor, setproductColor] = useState("");
  const [productSize, setproductSize] = useState({});
  const [productSizeList, setproductSizeList] = useState([]);
  const recentProduct = reletedProduct ? reletedProduct : [];

  const proDetailsCon = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const bgl = await fetch(
      URL.API_BASE_URL + `${URL.productDetails}/${id}`,
      requestOptions
    );

    const bg2 = await fetch(
      URL.API_BASE_URL + `${URL.productDetailsReleted}/${id}`,
      requestOptions
    );
    const bg3 = await fetch(
      URL.API_BASE_URL + `${URL.productDetailsReletedBrand}/${id}`,
      requestOptions
    );
    const bg4 = await fetch(
      URL.API_BASE_URL + `${URL.productShopDetails}/${id}`,
      requestOptions
    );
    const events = await bgl.json();
    const events2 = await bg2.json();
    const events3 = await bg3.json();
    const events4 = await bg4.json();
    const postData = events?.data;
    const postData2 = events2?.data;
    const postData3 = events3?.data;
    const postData4 = events4?.data;

    setpost(postData);
    setreletedProduct(postData2);
    setreletedProductbrand(postData3);
    setshopDetails(postData4);
  };

  useEffect(() => {
    proDetailsCon();

    return () => {};
  }, [id]);

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow:
      recentProduct?.length == 1
        ? 1
        : recentProduct?.length > 6
          ? 6
          : recentProduct?.length - 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            recentProduct?.length == 1
              ? 1
              : recentProduct?.length > 4
                ? 4
                : recentProduct?.length - 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:
            recentProduct?.length == 1
              ? 1
              : recentProduct?.length > 3
                ? 3
                : recentProduct?.length - 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:
            recentProduct?.length == 1
              ? 1
              : recentProduct?.length > 2
                ? 2
                : recentProduct?.length - 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settings2 = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow:
      reletedProductbrand?.length == 1
        ? 1
        : reletedProductbrand?.length > 6
          ? 6
          : reletedProductbrand?.length - 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            reletedProductbrand?.length == 1
              ? 1
              : reletedProductbrand?.length > 4
                ? 4
                : reletedProductbrand?.length - 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:
            reletedProductbrand?.length == 1
              ? 1
              : reletedProductbrand?.length > 3
                ? 3
                : reletedProductbrand?.length - 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:
            reletedProductbrand?.length == 1
              ? 1
              : reletedProductbrand?.length > 2
                ? 2
                : reletedProductbrand?.length - 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const hendleToAddItme = (e, id) => {
    if (productSize?.stock > 0) {
      dispatch(allapiAction.AddCartItme(e, productColor, productSize?._id));
    } else {
      toast.error("stock not available");
    }
  };

  let pageUrl;

  if (typeof window !== "undefined") {
    if (window.location !== undefined) {
      pageUrl = window.location.href;
    }
  }

  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  function copy() {
    const el = document.createElement("input");
    el.value = pageUrl;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    toast.success("Copied!");
  }

  const get_product_details = post;
  console.log(get_product_details?.features);
  const feturesdata = get_product_details?.features
    ? JSON.parse(get_product_details?.features)
    : [];

  console.log(feturesdata);
  const get_product_detailsm = useSelector((state) =>
    state?.allapi?.get_product_details ? state?.allapi?.get_product_details : {}
  );

  const [dispImg, setDispImg] = useState("");

  useEffect(() => {}, [id]);
  useEffect(() => {
    const feImg = URL.API_BASE_URL + get_product_details?.featureImage;
    console.log(get_product_details);
    if (get_product_details?.subcolor) {
      setproductColor(get_product_details?.subcolor[0]?._id);
      setproductSize(get_product_details?.subcolor[0]?.productsize[0]);
      setproductSizeList(get_product_details?.subcolor[0]?.productsize);

      const allImg = get_product_details?.subcolor[0]?.images?.map((data) => {
        return URL.API_BASE_URL + data?.img;
      });
      const allImgs = allImg?.filter((data) => {
        return !data.match("undefined");
      });

      if (allImg) {
        setDispImg(allImg[0]);
        setproductImage(allImg);
      }
    }
  }, [get_product_details]);

  const [showWhatsappIn, setShowWhatsappIn] = useState(false);
  const handleCloseWhatsappIn = () => setShowWhatsappIn(false);
  const handleOpneWhatsappIn = () => setShowWhatsappIn(true);

  const [showEmailIn, setShowEmailIn] = useState(false);
  const handleCloseEmailIn = () => setShowEmailIn(false);
  const handleOpneEmailIn = () => setShowEmailIn(true);

  const [whatInq, setwhatInq] = useState({ countryCode: "", whatsappNo: "" });
  const [emailInq, setemailInq] = useState({
    country: "",
    customerEmail: "",
    userName: "",
  });

  const hendleToWhatsapp = (e) => {
    const { name, value } = e.target;
    setwhatInq({ ...whatInq, [name]: value });
  };

  const hendleToEmail = (e) => {
    const { name, value } = e.target;
    setemailInq({ ...emailInq, [name]: value });
  };

  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode1, setCountryCode1] = useState("");
  const [phoneNumber1, setPhoneNumber1] = useState("");
  const [phoneNumber2, setPhoneNumber2] = useState("");
  const [countryCode12, setCountryCode12] = useState("");
  const [phoneNumber12, setPhoneNumber12] = useState("");

  const urltet = URL.API_BASE_URL_2 + "/product-details/";

  const feImg = URL.API_BASE_URL + get_product_details?.featureImage;

  const [value, setValue] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      setValue(localStorage.getItem("access_token"));
    } else {
      setValue("");
    }
  }, []);

  const [windowWidth, setWindowWidth] = useState(600);
  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Determine the width and height based on screen width
  const imageWidth = windowWidth <= 767 ? 150 : 300;
  const imageHeight = windowWidth <= 767 ? 150 : 300;

  return (
    <div className="overflowhidn">
      <div></div>
      <div className="ps-page--product p-25">
        <div className="ps-container">
          <div className="ps-page__container">
            <div className="ps-page__left">
              <div className="ps-product--detail ps-product--fullwidth">
                <div className="ps-product__header">
                  <div className="col-12 col-sm-6" data-vertical="true">
                    <div className="productPage__left">
                  
                      <div className="productPage__displayImageContainer">
                        <div className="productPage__displayImage">
                          <ReactImageMagnify
                            {...{
                              smallImage: {
                                alt: "Wristwatch by Ted Baker London",
                                isFluidWidth: true,
                                src: dispImg,
                                // sizes:
                                //   "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px",
                              },
                              largeImage: {
                                src: dispImg,
                                width: 1200,
                                height: 1800,
                              },

                              imageClassName: "imgViewS1",

                              enlargedImageStyle: {
                                width: "50px",
                              },
                              lensStyle: {
                                width: "20px",
                                height: "20px",
                              },
                              shouldUsePositiveSpaceLens: true,
                              shouldHideHintAfterFirstActivation: false,
                              lensStyle: {
                                backgroundColor: "rgba(0,0,0,.6)",
                              },
                              enlargedImageContainerStyle: {
                                zIndex: "1500",
                              },
                              enlargedImageContainerDimensions: {
                                width: "100%",
                                height: "100%",
                              },
                              enlargedImagePosition: "over",
                            }}
                          />
                        </div>
                      </div>
                      <div className="productPage__sideImagesContainer">
                        {productImage &&
                          productImage?.map((item, index) => {
                            return (
                              <div
                                key={index}
                                onClick={() => setDispImg(item)}
                                className={
                                  dispImg == item
                                    ? "sideImageActive"
                                    : "sideImage "
                                }
                              >
                                <Image
                                  src={item}
                                  alt={"ract"}
                                  width={300}
                                  height={250}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  <div className="ps-product__info">
                    <h1>{get_product_details?.title}</h1>
                    <h4 className="ps-product__price prDt">
                      ₹{productSize?.afterdiscountprice} – ₹
                      <del>{productSize?.price}</del>
                    </h4>

                    <div className="ps-product__desc">
                      <p>
                        Stock:
                        {productSize?.stock == 0 ? (
                          <a className="text-red-700">out of stock</a>
                        ) : (
                          <a className="text-green-700">
                            {productSize?.stock} Available
                          </a>
                        )}
                      </p>
                    </div>

                    <div className="ps-product__variations">
                      <figure>
                        <figcaption>Color</figcaption>

                        {get_product_details?.subcolor &&
                          get_product_details?.subcolor?.map((data, i) => {
                            const allImg = data?.images?.map((datasss) => {
                              return URL.API_BASE_URL + datasss?.img;
                            });
                            const allImgs = allImg?.filter((data) => {
                              return !data.match("undefined");
                            });
                            return (
                              <div
                                key={i}
                                style={{
                                  display: "inline-block",
                                  width: "30px",
                                  height: "30px",
                                  background: data?.color,
                                  marginRight: "5px",
                                  cursor: "pointer",
                                  border: "1px solid black",
                                }}
                                className="ps-variant--color ps-variant"
                                onClick={() => {
                                  setproductColor(data?._id);
                                  setDispImg(allImg[0]);
                                  setproductImage(allImg);
                                  setproductSize(data?.productsize[0]);
                                  setproductSizeList(data?.productsize);
                                }}
                              >
                                <span className="ps-variant__tooltip">
                                  {data?.color}
                                </span>
                              </div>
                            );
                          })}
                      </figure>
                    </div>
                    <div className="ps-product__variations">
                      <figure>
                        <figcaption>Size</figcaption>

                        {productSizeList &&
                          productSizeList?.map((data, i) => {
                            return (
                              <div
                                key={i}
                                className={
                                  data?._id == productSize?._id
                                    ? "selectedSize"
                                    : "unselectedSize"
                                }
                                style={{
                                  display: "inline-block",
                                  width: "30px",
                                  height: "30px",
                                  background: data?.color,
                                  marginRight: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setproductSize(data);
                                }}
                              >
                                {data?.size}
                              </div>
                            );
                          })}
                      </figure>
                    </div>
                    <div className="ps-product__shopping">
                      <a
                        className="ps-btn ps-btn--black"
                        href="#"
                        onClick={() => {
                          hendleToAddItme(get_product_details?._id);
                        }}
                      >
                        Add to cart
                      </a>
                    </div>
                    <div className="ps-product__specification">
                      <table>
                        <thead></thead>
                        <tbody>
                          <tr>
                            <th>
                              <b>Categories</b>
                            </th>
                            <th>
                              {" "}
                              {get_product_details?.categoryDetails?.[0]?.name}
                            </th>
                          </tr>
                          {feturesdata &&
                            feturesdata?.map((data, i) => {
                              return (
                                <tr key={i}>
                                  <th>
                                    <b>{data?.name}</b>
                                  </th>
                                  <th>{data?.value}</th>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                      {/* <a className="report" href="#">
                        Report Abuse
                      </a>
                      <p>
                        <strong>SKU:</strong> SF1133569600-1
                      </p> */}
                      {/* <p className="categories">
                        <strong> Categories:</strong>
                        <a href="#">
                          {get_product_details?.categoryDetails?.[0]?.name}
                        </a>
                      </p> */}
                    </div>
                    {/* <div className="ps-product__sharing">
                      <a className="facebook" href="#">
                        <i className="fa fa-facebook"></i>
                      </a>
                      <a className="twitter" href="#">
                        <i className="fa fa-twitter"></i>
                      </a>
                      <a className="google" href="#">
                        <i className="fa fa-google-plus"></i>
                      </a>
                      <a className="linkedin" href="#">
                        <i className="fa fa-linkedin"></i>
                      </a>
                      <a className="instagram" href="#">
                        <i className="fa fa-instagram"></i>
                      </a>
                    </div> */}
                  </div>
                </div>
                <div className="ps-product__content ps-tab-root">
                  <ul className="ps-tab-list">
                    <li className="active">
                      <a href="#tab-1">Description</a>
                    </li>
                  </ul>
                  <div className="ps-tabs">
                    <div className="ps-tab active" id="tab-1">
                      <div className="ps-document">
                        <div
                          className=""
                          dangerouslySetInnerHTML={{
                            __html: get_product_details?.description,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ps-page__right">
              {/* <aside className="widget widget_product widget_features">
                <div className="row">
                  <div className="col-12">
                    <img
                      width={100}
                      src={URL?.API_BASE_URL + shopDetails?.sellerProfile}
                      alt=""
                    />
                  </div>
                  <div className="col-12">
                    <h3>{shopDetails?.displayName} </h3>
                    <span className="mt-5">
                      {shopDetails?.city}, {shopDetails?.district},{" "}
                      {shopDetails?.state}, {shopDetails?.zip}
                    </span>
                  </div>
                  <div className="col-6">
                    <div
                      style={{
                        backgroundColor: "#7f097f1f",
                        textAlign: "center",
                        height: "80px",
                        paddingTop: "10px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#7f097f",
                        }}
                      >
                        Followers{" "}
                      </span>
                      <br />
                      <span> {shopDetails?.follower}</span>
                    
                    </div>
                  </div>
                  <div className="col-6">
                    <div
                      style={{
                        backgroundColor: "#7f097f1f",
                        textAlign: "center",
                        height: "80px",
                        paddingTop: "10px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#7f097f",
                        }}
                      >
                        Likes{" "}
                      </span>
                      <br />
                      <span> {shopDetails?.like}</span>
                     
                    </div>
                  </div>

                  
                  <div className="col-12">
                    <button
                      style={{
                        width: "100%",
                        backgroundColor: "#7f097f",
                        color: "#fff",
                        border: "none",
                        padding: "10px 0",
                        fontSize: "18px",
                        fontWeight: "700",
                        borderRadius: "0",
                        marginTop: "10px",
                        transition: "background-color 0.3s, color 0.3s",
                      }}
                      onClick={() => {
                        router("/vendor-store/" + shopDetails?._id);
                      }}
                    >
                      <i
                        className="fa fa-cart-arrow-down icon-bag2"
                        style={{ color: "#ffffff" }}
                      ></i>{" "}
                      Visit Store
                    </button>
                  </div>
                </div> 
               
              </aside> */}
              {/* <aside className="widget widget_ads">
                <a href="#">
                  <img src="/img/ads/product-ads.png" alt="" />
                </a>
              </aside> */}
            </div>
          </div>

          <div className="ps-deal-of-day shewBox">
            <div className="ps-container">
              <div className="ps-section__header">
                <div className="ps-block--countdown-deal">
                  <div className="ps-block__left">
                    <b>
                      {" "}
                      <h3>Related Product</h3>
                    </b>
                  </div>
                </div>
                <a href="#">View all</a>
              </div>
              <div className="ps-section__content mt-30">
                {recentProduct?.length > 0 && (
                  <Slider {...settings}>
                    {recentProduct &&
                      recentProduct?.map((data, i) => {
                        return (
                          <div className="recentlostfound" key={i}>
                            <div className="ps-product">
                              <div className="ps-product__thumbnail">
                                <Link to={"/product/" + data?.pro_sulg}>
                                  {/* <img
                                    src={URL.API_BASE_URL + data?.featureImage}
                                    alt=""
                                  /> */}
                                  <CoustomImg
                                    url={URL.API_BASE_URL + data?.featureImage}
                                    altkey={data?.title}
                                    w1={360}
                                      w2={360}
                                      h1={220}
                                      h2={290}
                                  />
                                </Link>
                              </div>
                              <div className="ps-product__container">
                                <div className="ps-product__content">
                                  <Link
                                    className="ps-product__title"
                                    to={"/product/" + data?.pro_sulg}
                                  >
                                    {data?.title}
                                  </Link>

                                  <p className="ps-product__price sale">
                                    ₹{data?.afterdiscountprice}{" "}
                                    <del>₹{data?.price} </del>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </Slider>
                )}
              </div>
            </div>
          </div>
          {reletedProductbrand?.length > 0 && (
            <div className="ps-deal-of-day shewBox">
              <div className="ps-container">
                <div className="ps-section__header">
                  <div className="ps-block--countdown-deal">
                    <div className="ps-block__left">
                      <b>
                        {" "}
                        <h3>Related Brand Product</h3>
                      </b>
                    </div>
                  </div>
                  <a href="#">View all</a>
                </div>
                <div className="ps-section__content mt-30">
                  {reletedProductbrand?.length > 0 && (
                    <Slider {...settings2}>
                      {reletedProductbrand &&
                        reletedProductbrand?.map((data, i) => {
                          return (
                            <div className="recentlostfound" key={i}>
                              <div className="ps-product">
                                <div className="ps-product__thumbnail">
                                  <Link to={"/product/" + data?.pro_sulg}>
                                    {/* <img
                                      src={
                                        URL.API_BASE_URL + data?.featureImage
                                      }
                                      alt=""
                                    /> */}
                                    <CoustomImg
                                      url={
                                        URL.API_BASE_URL + data?.featureImage
                                      }
                                      altkey={data?.title}
                                      w1={360}
                                      w2={360}
                                      h1={220}
                                      h2={290}
                                    />
                                  </Link>
                                </div>
                                <div className="ps-product__container">
                                  <div className="ps-product__content">
                                    <Link
                                      className="ps-product__title"
                                      to={"/product/" + data?.pro_sulg}
                                    >
                                      {data?.title}
                                    </Link>

                                    <p className="ps-product__price sale">
                                      ₹{data?.afterdiscountprice}{" "}
                                      <del>₹{data?.price} </del>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </Slider>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductDetailsPub;
