import React, { useEffect, useState } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";

import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";

import HeadingBack from "../../HeadingBack";
// import { useRouter } from "next/router";
// import { URL } from "@/Redux/common/url";
// import { allapiAction } from "@/Redux/common/action";
import SvgIcon from "../../const/SvgIcon";
import { Image } from "react-bootstrap";
import { allapiAction } from "../../../Redux/common/action";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { URL } from "../../../Redux/common/url";
import { toast } from "react-toastify";
import { AsyncPaginate } from "react-select-async-paginate";

function ProcessOrderComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");
  // const pagev = navigate?.query?.page;
  // const searchData = navigate?.query?.search;

  const { confirm } = Modal;
  console.log(id);
  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  const get_product_list = useSelector((state) =>
    state?.allapi?.get_listing_list ? state?.allapi?.get_listing_list : {}
  );
  const get_seller_all_list = useSelector((state) =>
    state?.allapi?.get_seller_all_list ? state?.allapi?.get_seller_all_list : {}
  );

  console.log(get_seller_all_list);

  const [pages, setPage] = useState(1);

  const listdata = get_product_list?.docs ? get_product_list?.docs : [];

  useEffect(() => {
    // if (search) {
    //   dispatch(allapiAction.searchListing({ page: page, key: search }));
    // } else {

    // }
    detdata();
    return () => {};
  }, [location.search]);

  const [first, setfirst] = useState({});
  const [productName, setproductName] = useState("");
  const [saledata, setsaledata] = useState({ amount: 0, commission: 0 });

  console.log(first);
  const [userselete, setuserselete] = useState(null);
  const hendletochangeamount = (e) => {
    const amount = e.target.value;

    const commpre = first?.totalSale >= 3000 ? 0.015 : 0.05;
    const comm = amount * commpre;

    setsaledata({
      amount,
      commission: comm,
    });
  };
  const detdata = async () => {
    const response = await dispatch(
      allapiAction.userDetailsbyvendorget({ _id: id })
    );

    setfirst(response?.data);
  };
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    getLocation();
    return () => {};
  }, []);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);

        
        },
        (error) => {
          setError(error.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  const [ListProduct, setListProduct] = useState([]);
  const hendletoSubmitOrder = async () => {
    //

    if (latitude == "") {
      toast?.error("please select location");
      return;
    }
    // if (saledata?.amount == 0) {
    //   toast?.error("please enter amount");
    // } else if (!saledata?.amount) {
    //   toast?.error("please enter amount");
    // } else if (!first?.userDetails) {
    //   toast?.error("please select user");
    // } else if (!productName) {
    //   toast?.error("please enter product names");
    // } else {
    const finaldata = ListProduct.map((item) => {
      return {
        product_id: item?.productId?._id,
        color: item?.productcolorId?._id,
        size: item?._id,
        item: item?.item,
      };
    });
    const response = await dispatch(
      allapiAction.createOrderbySales({
        _id: finaldata,
        userIdnew: id,
        user_name: get_seller_all_list?.userDetails?.fullName,
        user_email: get_seller_all_list?.userDetails?.email,
        user_mobile: get_seller_all_list?.userDetails?.phone,
        latitude: latitude,
        longitude: longitude,
        // user_name: get_seller_all_list?.userDetails?.fullName,
      })
    );
    if (response?.success) {
      navigate("/seller");
      // setfirst(response?.data);
      // setproductName("");
      // setsaledata({ amount: 0, commission: 0 });
    }
    // }
  };

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const hendleDelete = (e) => {
    // deleteProduct
    // setShowDelete(false);
    dispatch(allapiAction.deleteProduct({ productId: e, pageNo: page }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);

  console.log(ListProduct);

  const totalAmount = ListProduct?.reduce((total, obj) => {
    return total + obj?.afterdiscountprice * obj?.item;
  }, 0);

  const removebyindex = (e) => {
    const stdata = [...ListProduct];
    stdata.splice(e, 1);
    setListProduct(stdata);
  };

  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setuserData(e);
  };

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    // dispatch(
    //   allapiAction.addFoundByAdmin({
    //     id: userData?._id,
    //     amount: statusData?.amount,

    //     pageNumber: page,
    //   })
    // );

    if (!statusData.item) {
      toast?.error("please enter quantity");
      return;
    }
    const stdata = [...ListProduct];
    stdata.push({ ...userselete, item: statusData.item });
    setListProduct(stdata);
    setTimeout(() => {
      setuserselete(null);
    }, 300);

    setstatusDataBox(false);
    setstatusData({ item: "" });
  };

  const showModal = (e) => {
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: userId,
    //     status: userId,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: id,
    //     status: status,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const hendleToStatus = (id, e) => {
    // statusCategory

    // dispatch(allapiAction.statusProduct({ productId: id, status: e }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };
  const hendleToStatusfuture = (id, e) => {
    // statusCategory

    dispatch(
      allapiAction.futuredListing({ productId: id, status: e, pageNo: page })
    );
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <div style={{ width: "100px" }}>
              <img
                style={{ width: "100%" }}
                src={URL?.API_BASE_URL + item?.productcolorId?.featureImage}
                alt=""
              />
            </div>
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.productId?.title}
            </span>
          </>
        );
      },
    },

    // {
    //   title: "Name",
    //   dataIndex: "title",
    //   key: "title",
    // },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "After Discount Price",
      dataIndex: "afterdiscountprice",
      key: "afterdiscountprice",
    },
    {
      title: "itme count",
      dataIndex: "item",
      key: "item",
    },
    {
      title: "Total Price",
      dataIndex: "totalprice",
      key: "totalprice",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.afterdiscountprice * item?.item}
            </span>
          </>
        );
      },
    },
    {
      title: "GST",
      dataIndex: "gst",
      key: "gst",
    },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title="Edit">
              {/* <Link
                className="cursor-pointer"
                to={`/seller/listing/update-listing/${item?.pro_sulg}`}
                // onClick={() =>
                //   // navigate(
                //   //   `/seller/listing/update-listing/${item?.pro_sulg}`
                //   // )

                //   (window.location.href = `/seller/listing/update-listing/${item?.pro_sulg}`)
                // }
              >
                <Image
                  src={SvgIcon?.Pencil}
                  alt="Left Arrow"
                  width={20}
                  height={20}
                  style={{ verticalAlign: "middle" }}
                />
                
              </Link> */}
            </Tooltip>
            {/* <Tooltip title="delete">
              <button
                className="filter-button ml-3"
                onClick={() => showModal(item?.id)}
              >
                Delete
              </button>
            </Tooltip> */}
            {/* <Tooltip title="Add Point">
              <button
                className="filter-button ml-3  mt-5"
                onClick={() => hendleToViewM(item)}
              >
                Add Point
              </button>
            </Tooltip> */}
            <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                  removebyindex(index);
                }}
              >
                <Image
                  src={SvgIcon?.deletebtn}
                  alt="Left Arrow"
                  width={20}
                  height={20}
                  style={{ verticalAlign: "middle" }}
                />
                {/* <SvgIcon.deletebtn className="scale-icon edit-icon" /> */}
              </span>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/seller/listing?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    navigate("/seller/listing?page=" + e + "&&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  const loadOptionsPalyer = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/productGetforsalingbysalesman?key=${search}&page=${pages?.page}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label =
        data?.productId?.title +
        "-" +
        data?.productcolorId?.color +
        "-" +
        data?.size;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };
  return (
    <div>
      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <Modal
        title="Add Amount"
        centered
        open={statusDataBox}
        onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Select Product</Col>
            <Col span={24}>
              <AsyncPaginate
                value={userselete}
                className="liceninputv2"
                // placeholder="location"
                placeholder="Product"
                loadOptions={loadOptionsPalyer}
                onChange={setuserselete}
                additional={{
                  page: 1,
                }}
              />
            </Col>
            <Col span={24}>Itme Count</Col>
            <Col span={24}>
              <Input
                name="item"
                placeholder="enter itme count"
                value={statusData?.item}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Process Order"}
            // subHeading={`Total ${get_product_list?.totalDocs || 0} Listing`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          {/* <Tooltip title="Add">
            <span
              className="jop_btn"
              onClick={
                () => navigate("/seller/listing/add-listing")
                // (window.location.href = "/seller/listing/add-listing")
              }
            >
              Add
               
            </span>
          </Tooltip> */}
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={ListProduct}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 500000,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: get_product_list?.totalDocs
              ? get_product_list?.totalDocs
              : 0,
          }}
        />

        <div className="container">
          <div className="row">
            <div className="col-12">Total Amount : {totalAmount}</div>
            <div className="col-sm-4"></div>
            <div className="col-sm-4">
              <button
                className="btn btn-primary"
                onClick={() => {
                  hendleToViewM();
                }}
              >
                Add Product
              </button>
              {ListProduct?.length > 0 && (
                <button
                  className="btn btn-primary ml-10"
                  onClick={() => {
                    hendletoSubmitOrder();
                  }}
                >
                  Order Complete
                </button>
              )}
            </div>
            <div className="col-sm-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProcessOrderComponent;
