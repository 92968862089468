class UpiPaymentSDK {
  constructor() {
    this.requiredFields = [
      "vpa", // Virtual Payment Address (UPI ID)
      "amount", // Payment amount
      "payeeName", // Name of the payee
      "transactionRef", // Unique transaction reference
      "callbackUrl", // Callback URL for handling success/failure
    ];
  }

  validateObject(config) {
    const errorArray = [];
    this.requiredFields.forEach((field) => {
      if (!config[field]) {
        errorArray.push(field);
      }
    });
    return errorArray;
  }

  initializePayment(config) {
    return new Promise((resolve, reject) => {
      const errorArray = this.validateObject(config);
      if (errorArray.length > 0) {
        reject(
          new Error(`Missing required fields: ${JSON.stringify(errorArray)}`)
        );
        return;
      }

      // Create UPI payment URL with a callback URL
      const upiString = `upi://pay?pa=${encodeURIComponent(config.vpa)}&pn=${encodeURIComponent(config.payeeName)}&tid=${config.transactionRef}&am=${config.amount}&tn=${encodeURIComponent(config.transactionNote || "")}&cu=INR&url=${encodeURIComponent(config.callbackUrl)}`;

      // Open the UPI payment app
      const paymentWindow = window.open(upiString, "_blank");

      if (!paymentWindow) {
        reject(new Error("Unable to open payment window. Please try again."));
      } else {
        // Use an interval to check if the window is closed
        const checkWindowClosed = setInterval(() => {
          if (paymentWindow.closed) {
            clearInterval(checkWindowClosed);
            resolve({
              status:
                "Payment initiated. Check the result via callback or manual confirmation.",
            });
          }
        }, 1000);
      }
    });
  }
}

export default new UpiPaymentSDK();
