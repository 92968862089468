import { toast } from "react-toastify";
import { URL } from "../common/url";
import api from "../common/api";
import {
  LOADING,
  CREATE_CATEGORIES,
  GET_CATEGORIES,
  GET_SUB_SUB_CATEGORIES,
  GET_SELLER_PRODUCT_LIST,
  GET_SUB_SUB_CATE_LIST,
  OTP_BOX,
  GET_BUSS_PRO,
  GET_PACKAGE_LIST,
  GET_SELLER_TANSACTION,
  GET_PROFILE,
  GET_SELLER_TICKET_LIST,
  GET_MESSAGE_LISTS,
  GET_INVOICE,
  GET_PRODUCT_DETAILS,
  GET_SELLER_PRODUCT_LIST_PUBLICE,
  GET_TAGS_PRODUCT_LIST_PUBLICE,
  GET_SELLER_PRODUCT_LIST_PUBLICE_BY_CATEGORY,
  GET_SELLER_PROFILE,
  GET_SUB_SUB_CATE_LIST_FIL,
  GET_PAGES,
  GET_EMAIL_QU,
  GET_WHATSAPP_QU,
  PAGE_DETAILS,
  SEARCH_TAGS,
  SEARCH_PRODUCT_PUB,
  GET_FOOTER_DATA_ALL,
  GET_home_ALL_PRO,
  TOP_VIEW_ALL_TYPE,
  SELLER_LIST,
  DESHBOARD_STATE,
  SLIDER_LIST,
  BLOG_LIST,
  BLOG_DETAILS,
  GET_EXPIRE_DATE,
  home_POPS,
  FAQS_LIST,
  COUNTRY_LIST,
  LOADING_1,
  CITY_LIST,
  USER_PRODUCT_SERACH,
  GET_BUSS_PRO_1,
  ADD_CART_ITME,
  USER_PROFILE,
  CREATE_ADDRESS,
  ORDER_DETIALS,
  LOADING_CHECKOUT,
  SELLER_UPDATE_DETAILS,
  SELLER_DATE_REPORTS,
  MY_DIRECT_TEAM,
  GET_SUB_SUB_CATEGORY_LISTS,
  MY_INCOME_LIST,
  USER_INCOME_DATE,
  ALL_TEAM,
  WEEK_DATA,
  DAY_DATA,
  TEAM_COUNT,
  CHALLANGE_DATA,
  GET_PRODUCT_LISTS,
  GET_LISTING_LISTS,
  GET_LISTING_DETAILS,
  GET_SELLER_ALL_LIST,
  DESHBOARD,
  VENDOR_ORDER,
  LOST_AND_FOUND_PUBLICE_LIST,
  GET_VENDOR_PROFILES,
  GET_ALL_EDUCATION_CATEGORY,
  GET_ALL_EDUCATION_LIST,
  GET_MARQUES_LIST,
  GET_USE_LOST_AND_FOUND_LIST,
  GET_USER_CERTIFICITE_LIST,
  GET_CATEGORIES_FILTER,
  GET_STUDENT_VIDEO_LIST,
  GET_OUR_WORK_LIST,
  GET_VIDEO_WITH_PAGI_LIST,
  GET_Like_LIST,
  GET_PAYABLE_AMOUNT_LIST,
  GET_WITHDRAWAL_REQUEST_LIST,
  GET_ACCOUNT_PAYMENT,
  MESSAGE_PUBLICE_LIST,
  GET_JOB_PORTAL_ALL,
  GET_SUB_CATEGORY_ALL_LISTS,
  GET_MAIN_ALL_CATEGORY,
  GET_JOB_CATEGORY_PORTAL_ALL,
  GET_LOST_AND_FOUND_PU_DETAILS,
  GET_LOST_AND_FOUND_LIST_DETAILS,
  GET_RESUME_USER,
  GET_USER_APPLIST_JOB_LIST,
  GET_VENDOR_CREATED_JOBS,
  GET_JOBS_DETAILS,
  GET_JOBS_DETAILS_RELETED,
  GET_ALL_BRAND,
  BLOG,
  MARRIAGE_LIST,
  MARRIAGE_DETAILS,
  GET_RECENT_BLOG_LIST,
  GET_BLOG_CATEGOY_LIST,
} from "../common/constant";
const Token = require("../../Utils/Auth/Token");

const loading = (data) => {
  return { type: LOADING, payload: data };
};
const loading1 = (data) => {
  return { type: LOADING_1, payload: data };
};
const loadingCheckOut = (data) => {
  return { type: LOADING_CHECKOUT, payload: data };
};
const createcategoriesAction = (data) => {
  return { type: CREATE_CATEGORIES, payload: data };
};
const getcategoriesAction = (data) => {
  return { type: GET_CATEGORIES, payload: data };
};
const getcategoriesfilterAction = (data) => {
  return { type: GET_CATEGORIES_FILTER, payload: data };
};
const getsubsusbcategoriesAction = (data) => {
  return { type: GET_SUB_SUB_CATEGORIES, payload: data };
};
const getsubsusbcategoriesfillAction = (data) => {
  return { type: GET_SUB_SUB_CATE_LIST_FIL, payload: data };
};
const getsellerProductListAction = (data) => {
  return { type: GET_SELLER_PRODUCT_LIST, payload: data };
};
const getsellerProductListPublicAction = (data) => {
  return { type: GET_SELLER_PRODUCT_LIST_PUBLICE, payload: data };
};
const gettagsProductListPublicAction = (data) => {
  return { type: GET_TAGS_PRODUCT_LIST_PUBLICE, payload: data };
};
const getsellerProductListPublicbycategoryAction = (data) => {
  return { type: GET_SELLER_PRODUCT_LIST_PUBLICE_BY_CATEGORY, payload: data };
};
const userSearchProductAction = (data) => {
  return { type: USER_PRODUCT_SERACH, payload: data };
};
const userJobPortalAllAction = (data) => {
  return { type: GET_JOB_PORTAL_ALL, payload: data };
};
const usercategoryJobPortalAllAction = (data) => {
  return { type: GET_JOB_CATEGORY_PORTAL_ALL, payload: data };
};
const getsubsusbcateListAction = (data) => {
  return { type: GET_SUB_SUB_CATE_LIST, payload: data };
};
const otpBoxAction = (data) => {
  return { type: OTP_BOX, payload: data };
};
// const otpBoxForgotAction = (data) => {
//   return { type: OTP_BOX_FORGOT, payload: data };
// };
const getBussnissProfileAction = (data) => {
  return { type: GET_BUSS_PRO, payload: data };
};
const getBussnissProfile1Action = (data) => {
  return { type: GET_BUSS_PRO_1, payload: data };
};
const getpackageListAction = (data) => {
  return { type: GET_PACKAGE_LIST, payload: data };
};
const getticketListAction = (data) => {
  return { type: GET_SELLER_TICKET_LIST, payload: data };
};
const gettansactionListAction = (data) => {
  return { type: GET_SELLER_TANSACTION, payload: data };
};
const getprofileAction = (data) => {
  return { type: GET_PROFILE, payload: data };
};
const getmessageListAction = (data) => {
  return { type: GET_MESSAGE_LISTS, payload: data };
};
const getinvoiceAction = (data) => {
  return { type: GET_INVOICE, payload: data };
};
const getproductDetailsAction = (data) => {
  return { type: GET_PRODUCT_DETAILS, payload: data };
};
const getSellerDetailsAction = (data) => {
  return { type: GET_SELLER_PROFILE, payload: data };
};
const getPagesAction = (data) => {
  return { type: GET_PAGES, payload: data };
};
const getemailQuAction = (data) => {
  return { type: GET_EMAIL_QU, payload: data };
};
const getWhatsappQuAction = (data) => {
  return { type: GET_WHATSAPP_QU, payload: data };
};
const getpageDetailsAction = (data) => {
  return { type: PAGE_DETAILS, payload: data };
};
const getsearchtagssAction = (data) => {
  return { type: SEARCH_TAGS, payload: data };
};
const getsearchProductPubAction = (data) => {
  return { type: SEARCH_PRODUCT_PUB, payload: data };
};
const getfooterAllDataPubAction = (data) => {
  return { type: GET_FOOTER_DATA_ALL, payload: data };
};
const gethomeAllProAction = (data) => {
  return { type: GET_home_ALL_PRO, payload: data };
};
const topViewAllTypeAction = (data) => {
  return { type: TOP_VIEW_ALL_TYPE, payload: data };
};
const getSellerListPublicAction = (data) => {
  return { type: SELLER_LIST, payload: data };
};
const sellerDashboardStateAction = (data) => {
  return { type: DESHBOARD_STATE, payload: data };
};
const sliderListAction = (data) => {
  return { type: SLIDER_LIST, payload: data };
};
const blogListAction = (data) => {
  return { type: BLOG_LIST, payload: data };
};
const blogDetailsAction = (data) => {
  return { type: BLOG_DETAILS, payload: data };
};
const getExpireDateAction = (data) => {
  return { type: GET_EXPIRE_DATE, payload: data };
};
const homepopAction = (data) => {
  return { type: home_POPS, payload: data };
};
const faqsLsitAction = (data) => {
  return { type: FAQS_LIST, payload: data };
};
const countryLsitAction = (data) => {
  return { type: COUNTRY_LIST, payload: data };
};
const cityLsitAction = (data) => {
  return { type: CITY_LIST, payload: data };
};
const get_all_cart_itmeActione = (data) => {
  return { type: ADD_CART_ITME, payload: data };
};
const userprofileActione = (data) => {
  return { type: USER_PROFILE, payload: data };
};
const getAddressActione = (data) => {
  return { type: CREATE_ADDRESS, payload: data };
};
const orderDetailsActione = (data) => {
  return { type: ORDER_DETIALS, payload: data };
};
const getsellerprofilebysellerActione = (data) => {
  return { type: SELLER_UPDATE_DETAILS, payload: data };
};
const sellerDateWiseReportActione = (data) => {
  return { type: SELLER_DATE_REPORTS, payload: data };
};
const mydirectTeamActione = (data) => {
  return { type: MY_DIRECT_TEAM, payload: data };
};
const myincomeListActione = (data) => {
  return { type: MY_INCOME_LIST, payload: data };
};
const userincomedateActione = (data) => {
  return { type: USER_INCOME_DATE, payload: data };
};
const allteamActione = (data) => {
  return { type: ALL_TEAM, payload: data };
};
const weekdataActione = (data) => {
  return { type: WEEK_DATA, payload: data };
};
const daydataActione = (data) => {
  return { type: DAY_DATA, payload: data };
};
const teamCountActione = (data) => {
  return { type: TEAM_COUNT, payload: data };
};
const chalengeDataActione = (data) => {
  return { type: CHALLANGE_DATA, payload: data };
};

// seller
const getproductListAction = (data) => {
  return { type: GET_PRODUCT_LISTS, payload: data };
};
const getsubsubcategoryListAction = (data) => {
  return { type: GET_SUB_SUB_CATEGORY_LISTS, payload: data };
};
const getlistingListAction = (data) => {
  return { type: GET_LISTING_LISTS, payload: data };
};
const getlistingDetailsAction = (data) => {
  return { type: GET_LISTING_DETAILS, payload: data };
};
const getsellerAllListAction = (data) => {
  return { type: GET_SELLER_ALL_LIST, payload: data };
};
const deshboardAction = (data) => {
  return { type: DESHBOARD, payload: data };
};
const vendororderAction = (data) => {
  return { type: VENDOR_ORDER, payload: data };
};
const lostandfoundPubAction = (data) => {
  return { type: LOST_AND_FOUND_PUBLICE_LIST, payload: data };
};
const messagePubAction = (data) => {
  return { type: MESSAGE_PUBLICE_LIST, payload: data };
};
const marriageListPubAction = (data) => {
  return { type: MARRIAGE_LIST, payload: data };
};
const marriageDetailsAction = (data) => {
  return { type: MARRIAGE_DETAILS, payload: data };
};
const getvendorProfilsPubAction = (data) => {
  return { type: GET_VENDOR_PROFILES, payload: data };
};
const getalleducationcateAction = (data) => {
  return { type: GET_ALL_EDUCATION_CATEGORY, payload: data };
};
const getalleducationListAction = (data) => {
  return { type: GET_ALL_EDUCATION_LIST, payload: data };
};
const getmarquesListAction = (data) => {
  return { type: GET_MARQUES_LIST, payload: data };
};
const getuserLostAndFoundAction = (data) => {
  return { type: GET_USE_LOST_AND_FOUND_LIST, payload: data };
};
const getusercertificiteAction = (data) => {
  return { type: GET_USER_CERTIFICITE_LIST, payload: data };
};
const getstudentvideolistAction = (data) => {
  return { type: GET_STUDENT_VIDEO_LIST, payload: data };
};
const getourworkistAction = (data) => {
  return { type: GET_OUR_WORK_LIST, payload: data };
};
const getvideopagiAction = (data) => {
  return { type: GET_VIDEO_WITH_PAGI_LIST, payload: data };
};
const getuselikeAction = (data) => {
  return { type: GET_Like_LIST, payload: data };
};
const getpaysbleAction = (data) => {
  return { type: GET_PAYABLE_AMOUNT_LIST, payload: data };
};
const getwithdrawalRequsetAction = (data) => {
  return { type: GET_WITHDRAWAL_REQUEST_LIST, payload: data };
};
const getaccountpaymenttAction = (data) => {
  return { type: GET_ACCOUNT_PAYMENT, payload: data };
};
const getssubcateAllListAction = (data) => {
  return { type: GET_SUB_CATEGORY_ALL_LISTS, payload: data };
};

const getcateAllListAction = (data) => {
  return { type: GET_MAIN_ALL_CATEGORY, payload: data };
};

const getLostFoundDEtailsPuAction = (data) => {
  return { type: GET_LOST_AND_FOUND_PU_DETAILS, payload: data };
};
const getLostFoundDEtailsListPuAction = (data) => {
  return { type: GET_LOST_AND_FOUND_LIST_DETAILS, payload: data };
};
const getgetuserResumeAction = (data) => {
  return { type: GET_RESUME_USER, payload: data };
};
const getuserjobappliedlistAction = (data) => {
  return { type: GET_USER_APPLIST_JOB_LIST, payload: data };
};

const getVendorCreatedJobs = (data) => {
  return { type: GET_VENDOR_CREATED_JOBS, payload: data };
};
const getJobsDetailsAction = (data) => {
  return { type: GET_JOBS_DETAILS, payload: data };
};
const getJobsDetailsreletedAction = (data) => {
  return { type: GET_JOBS_DETAILS_RELETED, payload: data };
};
const getallbrandAction = (data) => {
  return { type: GET_ALL_BRAND, payload: data };
};
const blogAction = (data) => {
  return { type: BLOG, payload: data };
};
const getrecentblogListAction = (data) => {
  return { type: GET_RECENT_BLOG_LIST, payload: data };
};

const getblogCategoryLsitAction = (data) => {
  return { type: GET_BLOG_CATEGOY_LIST, payload: data };
};
export const getblogcateAllList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token ? URL.getblogcateAllList : URL.getblogcateAllList;
      // dispatch(loading(true));
      const response = await api.get(`${apiurldy}`, config);
      dispatch(getblogCategoryLsitAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getrectentBlog = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token ? URL.getrectentBlog : URL.getrectentBlog;
      // dispatch(loading(true));
      const response = await api.get(`${apiurldy}`, config);
      dispatch(getrecentblogListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getbrandAllListseller = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getbrandAllListseller}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getallbrandAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getjobreleted = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getjobreleted}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        dispatch(getJobsDetailsreletedAction(response?.data?.data));
      } else {
        dispatch(getJobsDetailsreletedAction(response?.data?.data));
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);

      return error?.response?.data;
    }
  };
};
export const getjobDetailst = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getjobDetailst}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        dispatch(getJobsDetailsAction(response?.data?.data));
      } else {
        dispatch(getJobsDetailsAction(response?.data?.data));
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);

      return error?.response?.data;
    }
  };
};
export const _getVendorCreatedJobs = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("access_token_vendor");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await api.get(URL.getVendorJobs + "/" + data, config);
      if (response.data.success) {
        dispatch(getVendorCreatedJobs(response.data.data));
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getJobsapplyedListVendor = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getJobsapplyedListVendor}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getuserjobappliedlistAction(response?.data?.data));
      } else {
        dispatch(getuserjobappliedlistAction(response?.data?.data));
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getJobsapplyedList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getJobsapplyedList}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getuserjobappliedlistAction(response?.data?.data));
      } else {
        dispatch(getuserjobappliedlistAction(response?.data?.data));
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const jobapplyNow = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.jobapplyNow}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);
        // dispatch(getLostFoundDEtailsListPuAction(response?.data?.data));
      } else {
        // dispatch(getwithdrawalRequsetAction(response?.data?.data));
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);

      return error?.response?.data;
    }
  };
};

export const getuserResume = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getuserResume}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getgetuserResumeAction(response?.data?.data));
      } else {
        dispatch(getgetuserResumeAction(response?.data?.data));
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const createResume = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      console.log(token);
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.createResume}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // dispatch(getLostFoundDEtailsListPuAction(response?.data?.data));
      } else {
        // dispatch(getwithdrawalRequsetAction(response?.data?.data));
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);

      return error?.response?.data;
    }
  };
};
export const lostfoundlist = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.lostfoundlist}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getLostFoundDEtailsListPuAction(response?.data?.data));
      } else {
        // dispatch(getwithdrawalRequsetAction(response?.data?.data));
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);

      return error?.response?.data;
    }
  };
};
export const lostfoundsdetails = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.lostfoundsdetails}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getLostFoundDEtailsPuAction(response?.data?.data));
      } else {
        // dispatch(getwithdrawalRequsetAction(response?.data?.data));
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);

      return error?.response?.data;
    }
  };
};
export const Withdrawalsrequset = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.Withdrawalsrequset}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getvendorProfilsPubAction(response?.data?.data));
      } else {
        // dispatch(getwithdrawalRequsetAction(response?.data?.data));
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);

      return error?.response?.data;
    }
  };
};

export const getWithdrawalListverndor = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getWithdrawalListverndor}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getwithdrawalRequsetAction(response?.data?.data));
      } else {
        dispatch(getwithdrawalRequsetAction(response?.data?.data));
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const vendorPaybleAmountLsitforuser = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.vendorPaybleAmountLsitforuser}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getpaysbleAction(response?.data?.data));
      } else {
        dispatch(getpaysbleAction(response?.data?.data));
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const vendorpaybleamdall = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.vendorpaybleamdall}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getaccountpaymenttAction(response?.data?.data));
      } else {
        dispatch(getaccountpaymenttAction(response?.data?.data));
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const userfollownow = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token ? URL.userfollownow : URL.userfollownowVender;
      const response = await api.post(`${apiurldy}`, data, config);
      if (response?.status == 200) {
        dispatch(getuselikeAction(response?.data?.data));
        // localStorage.setItem(
        //   "order_Address",
        //   JSON.stringify(response?.data?.data?._id)
        // );
        // navigate("/checkout");

        dispatch(loading(false));
      } else {
        // toast.error(response?.data?.message, {
        //   position: "top-right",
        //   autoClose: 300,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   // progress: undefined,
        //   // theme: "light",
        // });
      }

      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message, {
      //   position: "top-right",
      //   autoClose: 300,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   // progress: undefined,
      //   // theme: "light",
      // });
      return error?.response?.data;
    }
  };
};
export const userlikenow = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token ? URL.userlikenow : URL.userlikenowVender;
      const response = await api.post(`${apiurldy}`, data, config);
      if (response?.status == 200) {
        dispatch(getuselikeAction(response?.data?.data));
        // localStorage.setItem(
        //   "order_Address",
        //   JSON.stringify(response?.data?.data?._id)
        // );
        // navigate("/checkout");

        dispatch(loading(false));
      } else {
        // toast.error(response?.data?.message, {
        //   position: "top-right",
        //   autoClose: 300,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   // progress: undefined,
        //   // theme: "light",
        // });
      }

      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message, {
      //   position: "top-right",
      //   autoClose: 300,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   // progress: undefined,
      //   // theme: "light",
      // });

      return error?.response?.data;
    }
  };
};
export const userlikeget = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token ? URL.userlikeget : URL.userlikegetVender;
      const response = await api.post(`${apiurldy}`, data, config);
      if (response?.status == 200) {
        dispatch(getuselikeAction(response?.data?.data));
        // localStorage.setItem(
        //   "order_Address",
        //   JSON.stringify(response?.data?.data?._id)
        // );
        // navigate("/checkout");

        dispatch(loading(false));
      } else {
        // toast.error(response?.data?.message, {
        //   position: "top-right",
        //   autoClose: 300,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   // progress: undefined,
        //   // theme: "light",
        // });
      }
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message, {
      //   position: "top-right",
      //   autoClose: 300,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   // progress: undefined,
      //   // theme: "light",
      // });
    }
  };
};

export const studentVideoPubAll = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading(true));
      const response = await api.post(
        `${URL.studentVideoPubAll}`,
        data,
        config
      );
      dispatch(getvideopagiAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getourwork = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading(true));
      const response = await api.post(`${URL.getourwork}`, data, config);
      dispatch(getourworkistAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const studentVideoPub = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading(true));
      const response = await api.post(`${URL.studentVideoPub}`, data, config);
      dispatch(getstudentvideolistAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getusercertifitedelete = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token
        ? URL.getusercertifitedelete
        : URL.getusercertifitedeleteVender;
      // dispatch(loading(true));
      const response = await api.post(`${apiurldy}`, data, config);
      dispatch(getusercertificiteAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getusercertifiteList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token
        ? URL.getusercertifiteList
        : URL.getusercertifiteListVender;
      // dispatch(loading(true));
      const response = await api.get(`${apiurldy}/${data}`, config);
      dispatch(getusercertificiteAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const deletelostandfound = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token
        ? URL.deletelostandfound
        : URL.deletelostandfoundVender;
      // dispatch(loading(true));
      const response = await api.post(`${apiurldy}`, data, config);
      dispatch(getuserLostAndFoundAction(response?.data?.data));
      toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getuserlostandfoundnew = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token
        ? URL.getuserlostandfoundnew
        : URL.getuserlostandfoundnewVender;
      // dispatch(loading(true));
      const response = await api.get(`${apiurldy}/${data}`, config);
      dispatch(getuserLostAndFoundAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getMarqueesList2 = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getMarqueesList2}`, data, config);
      dispatch(getmarquesListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const geteducatListpub = (data, history) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.geteducatListpub}`, config);
      dispatch(getalleducationListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getLimiteducatListpub = (data, history) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.get(
        `${URL.geteducatListpub}?size=${data.size}`,
        config
      );
      dispatch(getalleducationListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const geteducatetttcate = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.geteducatetttcate}`, config);
      dispatch(getalleducationcateAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updateBussProfileByvendor = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.updateBussProfileByvendor}`,
        data,
        config
      );

      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // dispatch(otpBoxAction(true));
        dispatch(getvendorProfilsPubAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const kycUpdate = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.kycUpdate}`, data, config);

      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // dispatch(otpBoxAction(true));
        dispatch(getvendorProfilsPubAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updateBussLogo22header = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.updateBussLogo22header}`,
        data,
        config
      );

      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // dispatch(otpBoxAction(true));
        dispatch(getvendorProfilsPubAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updateBussLogovendor = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.updateBussLogovendor}`,
        data,
        config
      );

      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // dispatch(otpBoxAction(true));
        dispatch(getvendorProfilsPubAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getprofilevendor = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getprofilevendor}`,

        config
      );

      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // dispatch(otpBoxAction(true));
        dispatch(getvendorProfilsPubAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const lostfoundlistsearchpublic = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.lostfoundlistsearchpublic}`,
        data,
        config
      );

      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // dispatch(otpBoxAction(true));
        dispatch(lostandfoundPubAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const messagelistpublic = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.messagelistpublic}`, data, config);

      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // dispatch(otpBoxAction(true));
        dispatch(messagePubAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const MerriageList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.MerriageList}`, data, config);

      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // dispatch(otpBoxAction(true));
        dispatch(marriageListPubAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const Marriagedetaials = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.Marriagedetaials}`, data, config);

      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // dispatch(otpBoxAction(true));
        dispatch(marriageDetailsAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const lostfoundlistpublic = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");
      dispatch(lostandfoundPubAction({}));
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.lostfoundlistpublic}`,
        data,
        config
      );

      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // dispatch(otpBoxAction(true));
        dispatch(lostandfoundPubAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const vendordeshBoradData = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(
        `${URL.vendordeshBoradData}`,
        data,
        config
      );

      if (response?.data?.success) {
        dispatch(deshboardAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getSearchOrdervendor = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(
        `${URL.getSearchOrdervendor}`,
        data,
        config
      );

      if (response?.data?.success) {
        dispatch(vendororderAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getSearchReturnOrdervendor = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getSearchReturnOrdervendor}`,
        data,
        config
      );

      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // dispatch(otpBoxAction(true));
        dispatch(vendororderAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getuserreturnOrdervendor = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getuserreturnOrdervendor}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(vendororderAction(response?.data?.data));
      } else {
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getuserOrdervendor = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getuserOrdervendor}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(vendororderAction(response?.data?.data));
      } else {
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getuserOrderstatusUpdateProducation = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getuserOrderstatusUpdateProducation}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(vendororderAction(response?.data?.data));
      } else {
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getuserOrdersalesman = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getuserOrdersalesman}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(vendororderAction(response?.data?.data));
      } else {
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getuserOrderProducation = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getuserOrderProducation}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(vendororderAction(response?.data?.data));
      } else {
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};



export const visitAndPlan = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.visitAndPlan}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);
        //  dispatch(getlistingListAction(response?.data?.data));
      } else {
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));

      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const createOrderbySales = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.createOrderbySales}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);
        //  dispatch(getlistingListAction(response?.data?.data));
      } else {
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));

      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const userDetailsbyvendorget = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.userDetailsbyvendorget}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        //  dispatch(getlistingListAction(response?.data?.data));
      } else {
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));

      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const getsellersListFilter = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getsellersListFilter}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getsellerAllListAction(response?.data?.data));
      } else {
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellersList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getsellersList}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getsellerAllListAction(response?.data?.data));
      } else {
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const Certificatescreate = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.Certificatescreate}`,
        data,
        config
      );

      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // dispatch(otpBoxAction(true));
        // dispatch(getsearchProductPubAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const lostfoundsubmit = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.lostfoundsubmit}`, data, config);

      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // dispatch(otpBoxAction(true));
        // dispatch(getsearchProductPubAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const ListingDetails = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");
      console.log(data);
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading1(true));
      const response = await api.get(`${URL.ListingDetails}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        setTimeout(() => {
          dispatch(loading1(false));
        }, 300);

        dispatch(getlistingDetailsAction(response?.data?.data));
        return response;
      } else {
        // dispatch(getsellerProductListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
    } catch (error) {
      dispatch(loading1(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getsubcateAllList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getsubcateAllListseller}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getssubcateAllListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getcateAllList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getcateAllListseller}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getcateAllListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsubsubcate = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getsubsubcateseller}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getsubsubcategoryListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const deleteProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.deleteProduct}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getproductListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const searchListing = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.searchListing}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getlistingListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getBlogListVendor = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getBlogListVendor}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(blogAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const deleteBlogvendor = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.deleteBlogvendor}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(blogAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const futuredListing = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.futuredListing}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getlistingListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getsellerListings = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getsellerListings}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getlistingListAction(response?.data?.data));
      } else {
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getProductList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getProductList}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getproductListAction(response?.data?.data));
      } else {
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getExpireDate = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getExpireDate}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getExpireDateAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const createBlogs = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.createBlogs}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(blogAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const futuredProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.futuredProduct}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getproductListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getTodayPresent = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getTodayPresent}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const PresentOut = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.PresentOut}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const PresentIn = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.PresentIn}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const searchProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");
      dispatch(getproductListAction({}));
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.searchProduct}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getproductListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getpresentuserWiseDetailsuserside = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getpresentuserWiseDetailsuserside}`,
        data,
        config
      );

      if (response?.data?.success) {
        // toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      return error?.response?.data;
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getpresentuserList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");
      dispatch(getproductListAction({}));
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getpresentuserList}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getproductListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getvisitvendor = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");
      dispatch(getproductListAction({}));
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getvisitvendor}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getproductListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getexpenselistuser = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");
      dispatch(getproductListAction({}));
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getexpenselistuser}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getproductListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const addexpense = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");
      dispatch(getproductListAction({}));
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.addexpense}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getproductListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};

export const getChelenge = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getChelenge}`, data, config);

      console.log(response?.data?.data);

      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        dispatch(chalengeDataActione(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
        dispatch(chalengeDataActione(response?.data?.data));
      }

      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const createChelenge = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.createChelenge}`, data, config);

      console.log(response?.data?.data);

      if (response?.data?.success) {
        dispatch(chalengeDataActione(response?.data?.data));
        toast.success(response?.data?.message);
      } else {
        // dispatch(chalengeDataActione(response?.data?.data));
        toast.error(response?.data?.message);
      }
      // dispatch(userincomedateActione(response?.data?.data));

      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const userstatement = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.userstatement}`, data, config);

      console.log(response?.data?.data);
      dispatch(userincomedateActione(response?.data?.data));

      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const userMonthIncome = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.userMonthIncome}`, data, config);

      console.log(response?.data?.data);
      dispatch(myincomeListActione(response?.data?.data));

      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const createAddress = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token ? URL.createAddress : URL.createAddressVender;
      const response = await api.post(`${apiurldy}`, data, config);
      if (response?.status == 200) {
        // dispatch(createAddressAction(response?.data?.data));
        localStorage.setItem(
          "order_Address",
          JSON.stringify(response?.data?.data?._id)
        );
        navigate("/checkout");

        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message, {
          position: "top-right",
          autoClose: 300,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          // progress: undefined,
          // theme: "light",
        });
      }
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: undefined,
        // theme: "light",
      });
    }
  };
};

export const AddCartItme = (data, color, size, history) => {
  return async (dispatch) => {
    try {
      if (
        localStorage.getItem("access_token") ||
        localStorage.getItem("access_token_vendor")
      ) {
        toast.success("Product added", {
          position: "top-right",
          autoClose: 300,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          // progress: undefined,
          // theme: "light",
        });

        const token = await localStorage.getItem("access_token");
        const token2 = await localStorage.getItem("access_token_vendor");
        const token3 = token ? token : token2;
        const config = {
          headers: {
            Authorization: "Bearer " + token3,
          },
        };
        const apiurldy = token ? URL.addItemCart : URL.addItemCartVender;
        const response = await api.post(
          `${apiurldy}`,
          { product_id: data, color: color, size: size },
          config
        );

        dispatch(get_all_cart_itmeActione(response?.data?.data));

        // dispatch(productsAction.additemcart({ productId: e, variations: 0 }));
        return () => {};
      } else {
        console.log("lists");
        const e = data;
        if (e !== undefined) {
          console.log("lists");

          const blogIds = localStorage.getItem("cart_product");
          const blogIdss = blogIds !== null ? blogIds : "[]";
          const arr = JSON.parse(blogIdss);
          const alredyAdd = arr.indexOf(e);
          var lists = arr.filter((x) => {
            return x.product_id == e;
          });
          console.log(lists);
          if (lists?.length == 0) {
            arr.push({ product_id: e, color: color, size: size, item: 1 });
            localStorage.setItem("cart_product", JSON.stringify(arr));
            toast.success("Product added", {
              position: "top-right",
              autoClose: 300,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              // progress: undefined,
              // theme: "light",
            });
            // setTimeout(() => {
            // dispatch(
            //   productsAction.getcartProduct({
            //     data: arr,

            //   })
            // );

            console.log("test");
            const responsedd = await api.post(`${URL.getcartProduct}`, {
              data: arr,
            });
            dispatch(get_all_cart_itmeActione(responsedd?.data?.data));
            console.log(responsedd?.data?.data);
            return () => {};
            // }, 1000);
          } else {
            // arr.push({ product_id: e, variations: null });
            const finalresult = arr;
            toast.error("Product already added", {
              position: "top-right",
              autoClose: 300,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              // progress: undefined,
              // theme: "light",
            });
            localStorage.setItem("cart_product", JSON.stringify(finalresult));
            // dispatch(
            //   productsAction.getcartProduct({
            //     data: finalresult,
            //     userid: get_user_profile?.id,
            //   })
            // );
            return () => {};
          }
        } else {
        }
      }

      // const token = await localStorage.getItem("access_token");

      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };
      // // dispatch(loading(true));
      // const response = await api.post(`${URL.getOneUserPerCity}`, data, config);
      // dispatch(cityLsitAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const sellerDateWiseReport = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loadingCheckOut(true));
      const response = await api.post(
        `${URL.sellerDateWiseReport}`,
        data,
        config
      );

      dispatch(sellerDateWiseReportActione(response?.data?.data));
      toast.success(response?.data?.message);
      // console.log();
      // history.push("/");
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loadingCheckOut(false));
    } catch (error) {
      dispatch(loadingCheckOut(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createOrder = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token ? URL.createOrder : URL.createOrderVender;
      dispatch(loadingCheckOut(true));

      // const response2 = await api.get(`${URL.checkstocknow}`, config);
      const response = await api.post(`${apiurldy}`, data, config);
      if (response?.data?.success) {
        dispatch(get_all_cart_itmeActione(response?.data?.data));
        toast.success(response?.data?.message);
        // console.log();
        history("/accounts/order");
      } else {
        toast.error(response?.data?.message, {
          autoClose: false, // Don't automatically close the toast
        });
        dispatch(loadingCheckOut(false));
        // history.push("/shop-cart");
        // alert(response2?.data?.message)
      }
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loadingCheckOut(false));
    } catch (error) {
      dispatch(loadingCheckOut(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getDireactncomeUser = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token
        ? URL.getDireactncomeUser
        : URL.getDireactncomeUservendor;
      // dispatch(loading(true));
      const response = await api.post(`${apiurldy}`, data, config);

      console.log(response?.data?.data);
      dispatch(mydirectTeamActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getBonusIncomeUser = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token
        ? URL.getBonusIncomeUser
        : URL.getBonusIncomeUservendor;
      // dispatch(loading(true));
      const response = await api.post(`${apiurldy}`, data, config);

      console.log(response?.data?.data);
      dispatch(mydirectTeamActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getRelavelincomeUser = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token
        ? URL.getRelavelincomeUser
        : URL.getRelavelincomeUservendor;
      // dispatch(loading(true));
      const response = await api.post(`${apiurldy}`, data, config);

      console.log(response?.data?.data);
      dispatch(mydirectTeamActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getReMatchingIncomeUser = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token
        ? URL.getReMatchingIncomeUser
        : URL.getReMatchingIncomeUservendor;
      // dispatch(loading(true));
      const response = await api.post(`${apiurldy}`, data, config);

      console.log(response?.data?.data);
      dispatch(mydirectTeamActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getReDireactncomeUser = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token
        ? URL.getReDireactncomeUser
        : URL.getReDireactncomeUservendor;
      // dispatch(loading(true));
      const response = await api.post(`${apiurldy}`, data, config);

      console.log(response?.data?.data);
      dispatch(mydirectTeamActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getMatchingIncomeUser = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token
        ? URL.getMatchingIncomeUser
        : URL.getMatchingIncomeUservendor;
      // dispatch(loading(true));
      const response = await api.post(`${apiurldy}`, data, config);

      console.log(response?.data?.data);
      dispatch(mydirectTeamActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getlavelincomeUser = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token
        ? URL.getlavelincomeUser
        : URL.getlavelincomeUservendor;
      // dispatch(loading(true));
      const response = await api.post(`${apiurldy}`, data, config);

      console.log(response?.data?.data);
      dispatch(mydirectTeamActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const sellerDirectTeam = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token
        ? URL.sellerDirectTeam
        : URL.sellerDirectTeamvendor;
      // dispatch(loading(true));
      const response = await api.get(`${apiurldy}/${data}`, config);

      console.log(response?.data?.data);
      dispatch(mydirectTeamActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const userallteamlistfilter = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      dispatch(allteamActione({}));
      const response = await api.post(
        `${URL.userallteamlistfilter}`,
        data,
        config
      );

      // console.log(response?.data?.data);
      dispatch(allteamActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const userallteamlist = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token ? URL.userallteamlist : URL.userallteamlistvendor;
      // dispatch(loading(true));
      // const response = await api.get(`${apiurldy}/${data}`, config);
      dispatch(allteamActione({}));
      const response = await api.post(`${apiurldy}`, data, config);

      // console.log(response?.data?.data);
      dispatch(allteamActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerprofilebyseller = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getsellerprofilebyseller}`, config);

      console.log(response?.data?.data);
      dispatch(getsellerprofilebysellerActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const weekWiseSale = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.weekWiseSale}`, config);

      console.log(response?.data?.data);

      dispatch(weekdataActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const dayWiseSale = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.dayWiseSale}`, config);

      console.log(response?.data?.data);

      dispatch(daydataActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const myTeamCount = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.myTeamCount}`, config);

      console.log(response?.data?.data);

      dispatch(teamCountActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getUserAddress = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token ? URL.getUserAddress : URL.getUserAddressVender;
      // dispatch(loading(true));
      const response = await api.post(`${apiurldy}`, data, config);
      dispatch(getAddressActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getcartitme = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token ? URL.getcartitme : URL.getcartitmeVender;
      // dispatch(loading(true));
      const response = await api.post(`${apiurldy}`, data, config);
      dispatch(get_all_cart_itmeActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const removeCartItem = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token ? URL.removeCartItem : URL.removeCartItemVender;
      // dispatch(loading(true));
      const response = await api.post(`${apiurldy}`, data, config);
      dispatch(get_all_cart_itmeActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const removeAllCartItem = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token
        ? URL.removeAllCartItem
        : URL.removeAllCartItemVender;
      // dispatch(loading(true));
      const response = await api.post(`${URL.apiurldy}`, data, config);
      dispatch(get_all_cart_itmeActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const removeItemCartCount = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token
        ? URL.removeItemCartCount
        : URL.removeItemCartCountVender;
      // dispatch(loading(true));
      const response = await api.post(`${apiurldy}`, data, config);

      // if (condition) {

      // } else {

      // }

      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(get_all_cart_itmeActione(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const addItemCartCount = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token
        ? URL.addItemCartCount
        : URL.addItemCartCountVender;
      // dispatch(loading(true));
      const response = await api.post(`${apiurldy}`, data, config);
      dispatch(get_all_cart_itmeActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const afterLoginAddCart = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.afterLoginAddCart}`, data, config);

      console.log(response?.data?.data);
      dispatch(get_all_cart_itmeActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getcartProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getcartProduct}`, data, config);
      dispatch(get_all_cart_itmeActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const addItemCart = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token ? URL.addItemCart : URL.addItemCartVender;
      // dispatch(loading(true));
      const response = await api.post(`${apiurldy}`, data, config);
      dispatch(get_all_cart_itmeActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getOneUserPerCity = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getOneUserPerCity}`, data, config);
      dispatch(cityLsitAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getOneUserPerCountry = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getOneUserPerCountry}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(countryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getlistfaqs = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getlistfaqs}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(faqsLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const gethomePop = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.gethomePop}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(homepopAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getBlogListPub = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getBlogListPub}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(blogListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const sellerDashboardState = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading(true));
      const response = await api.get(`${URL.sellerDashboardState}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      // dispatch(loading(false));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(sellerDashboardStateAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getSellerListPublic = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getSellerListPublic}`,
        data,
        config
      );
      dispatch(getSellerListPublicAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const createContactqueries = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.createContactqueries}`,
        data,
        config
      );
      // dispatch(topViewAllTypeAction(response?.data?.data));
      toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const topViewnewSeller = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.topViewnewSeller}`, data, config);
      dispatch(topViewAllTypeAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getsliderList = (data, history) => {
  return async (dispatch) => {
    try {
      dispatch(sliderListAction({}));
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getsliderList}`, data, config);

      dispatch(sliderListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getBlogDetails = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      // dispatch(loading(true));
      const response = await api.post(`${URL.getBlogDetails}`, data, config);
      dispatch(blogDetailsAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
    }
  };
};
export const applyforrefferalProgram = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      // dispatch(loading(true));
      const response = await api.post(
        `${URL.applyforrefferalProgram}`,
        data,
        config
      );
      // dispatch(blogDetailsAction(response?.data?.data));
      dispatch(loading(false));
      toast.success(response?.data?.message);
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};

export const gethomePro = (data, history) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      dispatch(loading(true));
      const response = await api.post(`${URL.gethomePro}`, data, config);
      dispatch(gethomeAllProAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
    }
  };
};

export const footerPagepageListetPu = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.footerPagepageListetPu}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getfooterAllDataPubAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const passwordChange = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.passwordChange}`, data, config);

      if (response?.data?.success) {
        toast.success(response?.data?.message);
        // dispatch(otpBoxAction(true));

        // dispatch(getsearchProductPubAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const sendOtpSeller = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.sendOtpSeller}`, data, config);

      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        dispatch(otpBoxAction(true));

        // dispatch(getsearchProductPubAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const searchProductpub = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.searchProductpub}`, data, config);

      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getsearchProductPubAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createSellerProducsdft = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.createSellerProduct}`,
        data,
        config
      );

      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const searchtags = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.searchtags}`, data, config);
      dispatch(getsearchtagssAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const pageDetails = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.pageDetails}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getpageDetailsAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getPages = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getPages}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getPagesAction(response?.data?.data));
      } else {
        dispatch(getPagesAction(response?.data?.data));
        // toast.error(response?.data?.message);
      }
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getEmailenquirySeller = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getEmailenquirySeller}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getemailQuAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerWhatsappinquire = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getsellerWhatsappinquire}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getWhatsappQuAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsubsubCategoryList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getsubsubCategoryList}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getsubsusbcategoriesfillAction(response?.data?.data));
      } else {
        dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const productDetails = (data, history) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading1(true));
      const response = await api.get(`${URL.productDetails}/${data}`, config);
      if (response?.data?.success) {
        setTimeout(() => {
          dispatch(loading1(false));
        }, 300);

        dispatch(getproductDetailsAction(response?.data?.data));
        return response;
      } else {
        dispatch(getsellerProductListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
    } catch (error) {
      dispatch(loading1(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const emailenquirysubmit = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.emailenquirysubmit}`,
        data,
        config
      );
      // dispatch(createcategoriesAction(response?.data?.data));
      toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));

      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const whatsappinquiresubmit = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.whatsappinquiresubmit}`,
        data,
        config
      );
      // dispatch(createcategoriesAction(response?.data?.data));
      toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const createcategories = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.createcategories}`, data, config);
      dispatch(createcategoriesAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const productclick = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.productclick}`, data, config);
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        // toast.error(response?.data?.message);
      }
      // dispatch(getSellerDetailsAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";

      toast.success();
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const createSubscription = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.createSubscription}`,
        data,
        config
      );
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }
      // dispatch(getSellerDetailsAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";

      toast.success();
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerprofile = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getsellerprofile}`, data, config);
      dispatch(getSellerDetailsAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const gettagsProductListPublice = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.gettagsProductListPublice}`,
        data,
        config
      );
      dispatch(gettagsProductListPublicAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerProductListPublice = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getsellerProductListPublice}`,
        data,
        config
      );
      dispatch(getsellerProductListPublicAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerProductListPublicefilCate = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading(true));
      const response = await api.post(
        `${URL.getsellerProductListPublicefilCate}`,
        data,
        config
      );
      dispatch(getsellerProductListPublicAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getsellerProductListPublicebymaincategory2 = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading(true));
      const response = await api.post(
        `${URL.getsellerProductListPublicebymaincategory2}`,
        data,
        config
      );
      dispatch(
        getsellerProductListPublicbycategoryAction(response?.data?.data)
      );
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerProductListPublicebycategory = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading(true));
      const response = await api.post(
        `${URL.getsellerProductListPublicebycategory}`,
        data,
        config
      );
      dispatch(
        getsellerProductListPublicbycategoryAction(response?.data?.data)
      );
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));

      return response;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerProductListbycategoryNew = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading(true));
      const response = await api.post(
        `${URL.getsellerProductListbycategoryNew}`,
        data,
        config
      );
      dispatch(
        getsellerProductListPublicbycategoryAction(response?.data?.data)
      );
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));

      return response;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const userSearchListingsUser = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading(true));
      const response = await api.post(
        `${URL.userSearchListingsUser}`,
        data,
        config
      );
      dispatch(userSearchProductAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));

      return response;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const userSearchListings = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading(true));
      const response = await api.post(
        `${URL.userSearchListings}`,
        data,
        config
      );
      dispatch(userSearchProductAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));

      return response;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const userSearchProductuser = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading(true));
      const response = await api.post(
        `${URL.userSearchProductuser}`,
        data,
        config
      );
      dispatch(userSearchProductAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));

      return response;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const userSearchProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading(true));
      const response = await api.post(`${URL.userSearchProduct}`, data, config);
      dispatch(userSearchProductAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));

      return response;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

// job portal
export const getjobcategoryWiseList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading(true));
      const response = await api.post(
        `${URL.getjobcategoryWiseList}`,
        data,
        config
      );
      dispatch(userJobPortalAllAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));

      return response;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getjoballpageList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading(true));
      const response = await api.post(`${URL.getjoballpageList}`, data, config);
      dispatch(userJobPortalAllAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));

      return response;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getjobCategoryallpageList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading(true));
      const response = await api.post(
        `${URL.getjobCategoryallpageList}`,
        data,
        config
      );
      dispatch(usercategoryJobPortalAllAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));

      return response;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const createSellerProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.createSellerProduct}`,
        data,
        config
      );

      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const copyProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.copyProduct}`, data, config);

      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updatesellerProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.updatesellerProduct}`,
        data,
        config
      );

      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const searchSellerProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.searchSellerProduct}`,
        data,
        config
      );

      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }
      // dispatch(getsellerProductListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const deleteSellerProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.deleteSellerProduct}`,
        data,
        config
      );
      dispatch(getsellerProductListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerProductList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getsellerProductList}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        dispatch(getsellerProductListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getsellerProductListbysubcategoryNew = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getsellerProductListbysubcategoryNew}`,
        data,
        config
      );

      dispatch(
        getsellerProductListPublicbycategoryAction(response?.data?.data)
      );
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsubcategryListbymsubCategory = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getsubcategryListbymsubCategory}`,
        data,
        config
      );

      dispatch(getcategoriesfilterAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsubcategryListbymainCategory = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getsubcategryListbymainCategory}`,
        data,
        config
      );

      dispatch(getcategoriesfilterAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getfiltermaincategory = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getfiltermaincategory}`,
        data,
        config
      );

      dispatch(getcategoriesfilterAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getfiltercategory = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getfiltercategory}`, data, config);

      dispatch(getcategoriesfilterAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getCategory = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getCategory}`, data, config);

      dispatch(getcategoriesAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsubsubcateList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getsubsubcate}`, data, config);

      dispatch(getsubsusbcateListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsubsubcatehome = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getsubsubcatehome}`, data, config);

      dispatch(getsubsusbcategoriesAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const signinSeller = (data, navigate) => {
  return async (dispatch) => {
    try {
      const response = await api.post(`${URL.signinSeller}`, data);
      if (response?.data?.success) {
        dispatch(userprofileActione(response?.data?.data));

        toast.success(response?.data?.message);

        if (data?.role_name == "User") {
          Token.setToken(response?.data?.api_token);

          const lsValue = JSON.stringify(response?.data?.data);
          Token.setUserDetail(lsValue);
          // navigate(-1);
          navigate("/accounts"); //smth
          // window.location.href = "/accounts";
        } else {
          Token.setvendorToken(response?.data?.api_token);

          const lsValue = JSON.stringify(response?.data?.data);
          Token.setUserDetail(lsValue);

          if (response?.data?.data?.isStaf == "1") {
            if (response?.data?.data?.stafftype == "production") {
              navigate("/producation");
            } else {
              navigate("/account-sales-man");
            }
          } else {
            navigate("/seller");
          }

          // navigate(-1);
          // smth
          // window.location.href = "/seller";
        }

        // setTimeout(, 3000);
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(loading(false));

      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };
};
export const signinSellereSecurite = (data, navigate) => {
  return async (dispatch) => {
    try {
      const response = await api.post(`${URL.signinSellereSecurite}`, data);
      if (response?.status == 200) {
        // toast.success(response?.data?.message);

        Token.setToken(response?.data?.api_token);
        const lsValue = JSON.stringify(response?.data?.data);
        Token.setUserDetail(lsValue);
        // navigate("/home"),
        // setTimeout(navigate("/home"), 1000);
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const signupSeller = (data, navigate) => {
  return async (dispatch) => {
    try {
      const response = await api.post(`${URL.signupSeller}`, data);
      if (response?.status == 201) {
        // toast.success(response?.data?.message);

        if (response?.data?.success == true) {
          dispatch(otpBoxAction(true));
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.data?.message);
        }
        // otpBoxAction
        // Token.setToken(response?.data?.api_token);
        // setTimeout(navigate("/home"), 1000);
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const otpVerify = (data, navigate) => {
  return async (dispatch) => {
    try {
      const response = await api.post(`${URL.otpVerify}`, data);
      if (response?.status == 200) {
        // toast.success(response?.data?.message);

        if (response?.data?.success == true) {
          dispatch(otpBoxAction(false));
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.data?.message);
        }
        // otpBoxAction
        // Token.setToken(response?.data?.api_token);
        // navigate("/login"); smth
        // setTimeout(navigate("/login"), 1000);
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const otpVerifyseller = (data, navigate) => {
  return async (dispatch) => {
    try {
      const response = await api.post(`${URL.otpVerifyseller}`, data);
      if (response?.status == 200) {
        // toast.success(response?.data?.message);

        if (response?.data?.success == true) {
          dispatch(otpBoxAction(false));
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.data?.message);
        }
        // otpBoxAction
        // Token.setToken(response?.data?.api_token);
        // setTimeout(navigate("/login"), 1000);
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const createBussnissPro = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(`${URL.createBussnissPro}`, data, config);
      dispatch(getBussnissProfileAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const updateBussLogo = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(`${URL.updateBussLogo}`, data, config);
      dispatch(getBussnissProfileAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const updateBussProfile = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(`${URL.updateBussProfile}`, data, config);
      dispatch(getBussnissProfileAction(response?.data?.data));

      if (response?.data?.success) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updateWhatsaapNumber = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(
        `${URL.updateWhatsaapNumber}`,
        data,
        config
      );
      // dispatch(getBussnissProfileAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const verifyWhatsapp = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(`${URL.verifyWhatsapp}`, data, config);
      dispatch(getBussnissProfileAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getBussProfile = (data, history) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.get(`${URL.getBussProfile}`, config);
      dispatch(getBussnissProfileAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const edituserProfileByuser = (data, history) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(
        `${URL.edituserProfileByuser}`,
        data,
        config
      );
      dispatch(getBussnissProfile1Action(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getBussProfile1 = (data, history) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.get(`${URL.getBussProfile1}`, config);
      dispatch(getBussnissProfile1Action(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getPackageList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getPackageList}/${data}`, config);
      dispatch(getpackageListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const createPurchase = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(`${URL.createPurchase}`, data, config);
      // dispatch(getBussnissProfileAction(response?.data?.data));

      if (response?.data?.success) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getsellerTransaction = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getsellerTransaction}/${data}`,
        config
      );
      dispatch(gettansactionListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getuserOrder = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token ? URL.getuserOrder : URL.getuserOrderVender;
      // dispatch(loading(true));
      const response = await api.get(`${apiurldy}/${data}`, config);
      dispatch(gettansactionListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getuserOrdernew = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token ? URL.getuserOrdernew : URL.getuserOrdernewVender;
      // dispatch(loading(true));
      const response = await api.get(`${apiurldy}/${data}`, config);
      dispatch(gettansactionListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getprofile = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };

      const apiurldy = token ? URL.getprofile : URL.getprofilerVender;
      const response = await api.get(`${apiurldy}`, config);
      dispatch(getprofileAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updateUserProfile = (data, history) => {
  return async (dispatch) => {
    try {
      const userToken = localStorage.getItem("access_token");
      const vendorToken = localStorage.getItem("access_token_vendor");
      const token = vendorToken ? vendorToken : userToken;

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const apiurldy = userToken ? URL.getprofile : URL.updateVenderProfile;
      const response = await api.put(`${apiurldy}`, data, config);

      if (response?.data?.success) {
        dispatch(getvendorProfilsPubAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getuserBussnissMonth = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getuserBussnissMonth}`, config);
      dispatch(sellerDashboardStateAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getuserdeshboardData = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getuserdeshboardData}`, config);
      dispatch(sellerDashboardStateAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getInvoice = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getInvoice}`, config);
      dispatch(getinvoiceAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

// createSellerTickets

export const createSellerTickets = (data, history) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token");

      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };
      // // dispatch(loading(true));
      // const response = await api.post(
      //   `${URL.createSellerTickets}`,
      //   data,
      //   config
      // );

      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token
        ? URL.createSellerTickets
        : URL.VemdorcreateSellerTickets;
      const response = await api.post(`${apiurldy}`, data, config);
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getticketListAction(response?.data?.data));
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getuserOrderDetailsupdateStockProduction = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token
        ? URL.getuserOrderDetailsupdateStockProduction
        : URL.getuserOrderDetailsupdateStockProduction;
      // dispatch(loading(true));

      console.log(data);
      const response = await api.post(`${apiurldy}`, data, config);

      if (response?.data?.success) {
        console.log(response?.data?.data);
        // toast.success(response?.data?.message);

        dispatch(orderDetailsActione(response?.data?.data));
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getuserOrderDetailsProduction = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token
        ? URL.getuserOrderDetailsProduction
        : URL.getuserOrderDetailsProductionvendor;
      // dispatch(loading(true));

      console.log(data);
      const response = await api.post(`${apiurldy}`, data, config);

      if (response?.data?.success) {
        console.log(response?.data?.data);
        // toast.success(response?.data?.message);

        dispatch(orderDetailsActione(response?.data?.data));
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const collectAmount = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token ? URL.collectAmount : URL.collectAmount;
      // dispatch(loading(true));

      console.log(data);
      const response = await api.post(`${apiurldy}`, data, config);

      if (response?.data?.success) {
        console.log(response?.data?.data);
        // toast.success(response?.data?.message);

        dispatch(orderDetailsActione(response?.data?.data));
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));

      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const getuserOrderDetails = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token
        ? URL.getuserOrderDetails
        : URL.getuserOrderDetailsVender;
      // dispatch(loading(true));

      console.log(data);
      const response = await api.post(`${apiurldy}`, data, config);

      if (response?.data?.success) {
        console.log(response?.data?.data);
        // toast.success(response?.data?.message);

        dispatch(orderDetailsActione(response?.data?.data));
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getSellerTicketList = (data, history) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token");

      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };

      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token
        ? URL.getSellerTicketList
        : URL.VemdorgetSellerTicketList;
      // dispatch(loading(true));
      const response = await api.get(`${apiurldy}/${data}`, config);
      // dispatch(allteamActione({}));
      // const response = await api.post(`${apiurldy}`, data, config);
      // // dispatch(loading(true));
      // const response = await api.get(
      //   `${URL.getSellerTicketList}/${data}`,
      //   config
      // );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getticketListAction(response?.data?.data));
      } else {
        dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getmessageSupportList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getmessageSupportList}`,
        data,
        config
      );

      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getmessageListAction(response?.data?.data));
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createMessage = (data, history) => {
  return async (dispatch) => {
    try {
      // const token = await localStorage.getItem("access_token");

      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      // };
      // // dispatch(loading(true));
      // const response = await api.post(`${URL.createMessage}`, data, config);

      const token = await localStorage.getItem("access_token");
      const token2 = await localStorage.getItem("access_token_vendor");
      const token3 = token ? token : token2;
      const config = {
        headers: {
          Authorization: "Bearer " + token3,
        },
      };
      const apiurldy = token ? URL.createMessage : URL.VemdorcreateMessage;
      // dispatch(loading(true));
      const response = await api.post(`${apiurldy}`, data, config);

      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getmessageListAction(response?.data?.data));
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const deleteacountByseller = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.deleteacountByseller}`,
        data,
        config
      );

      if (response?.data?.success) {
        localStorage.clear();
        // navigate("/"); smth
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
